import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';

import { useGlobalErrorStore } from '@/components/dialogs';
import { StepsNavigation } from '@/pages/auth/components/StepsNavigation';
import { StyledFormProvider, StyledFormTitle, StyledWideFormRoot } from '@/pages/auth/components/styles';
import { TOTAL_STEPS } from '@/pages/auth/sign-up/constants';
import type { AddressFormFields } from '@/pages/auth/sign-up/forms/AddressForm/AddressForm';
import { AddressForm, getDefaultAddressFormValues } from '@/pages/auth/sign-up/forms/AddressForm/AddressForm';
import { useNavigation } from '@/pages/auth/sign-up/hooks/useNavigation';
import { useFetchContractorByTin } from '@/pages/auth/sign-up/hooks/useSignupData.ts';
import { AutocompleteModal } from '@/pages/auth/sign-up/modals/AutocompleteModal';
import {
  useAutocompleteModalStoreActions,
  useAutocompleteModalStoreFieldName,
} from '@/pages/auth/sign-up/store/autocompleteModal.store';
import type { SignUpCompany } from '@/pages/auth/sign-up/store/signUp.store';
import {
  useSignUpStoreActions,
  useSignUpStoreCompanyData,
  useSignUpStoreUserData,
} from '@/pages/auth/sign-up/store/signUp.store';
import { rawPhoneNumberFormat } from '@/shared/helpers/format.helpers.ts';
import type { AfterSubmit } from '@/shared/types/common.types';

import { companyFormSchema } from '../validation-schemas';
import type { CompanyFormFields } from './components/CompanyInfoForm';
import { CompanyInfoForm, getDefaultCompanyFormValues } from './components/CompanyInfoForm';

export type FormValues = AfterSubmit & CompanyFormFields & AddressFormFields;
type FormFieldsKeys = keyof FormValues;
type FormFieldsValues = FormValues[FormFieldsKeys];

// ----------------------------------------------------------------------

export const CompanyForm = () => {
  const { goToPreviousStep, goToNextStep, currentStepIndex } = useNavigation();

  const { setCompanyData } = useSignUpStoreActions();
  const storedCompanyData = useSignUpStoreCompanyData();
  const userData = useSignUpStoreUserData();
  const { phoneNumber } = userData;

  const selectedFieldName = useAutocompleteModalStoreFieldName();
  const { closeAutocompleteModal } = useAutocompleteModalStoreActions();
  const { t } = useTranslation('auth-sign-up');

  const { setGlobalError } = useGlobalErrorStore();
  const shouldPrefillForm = !!storedCompanyData;
  const userPhone = phoneNumber ? phoneNumber : '';

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(companyFormSchema),
    defaultValues: {
      ...getDefaultCompanyFormValues(shouldPrefillForm, storedCompanyData),
      ...getDefaultAddressFormValues(shouldPrefillForm, storedCompanyData, userPhone),
    },
  });

  const {
    setValue,
    formState: { isSubmitting },
  } = methods;

  const submitForm = (formData: FormValues) => {
    const companyData: SignUpCompany = {
      companyName: formData.companyName,
      tin: formData.tin,
      legalStatus: formData.legalStatus,
      region: formData.region,
      city: formData.city,
      cityDistrict: formData.cityDistrict,
      streetType: formData.streetType,
      street: formData.street,
      house: formData.house,
      building: formData.building,
      apartment: formData.apartment,
      phoneNumber: formData.phoneNumber,
      postalCode: formData.postalCode,
    };

    setCompanyData(companyData);

    goToNextStep();
  };

  const handleBackwardClick = () => {
    goToPreviousStep();
  };

  const handleChooseOption = (_event: React.SyntheticEvent, value: FormFieldsValues) => {
    setValue(selectedFieldName as FormFieldsKeys, value, { shouldDirty: true });
    closeAutocompleteModal();
  };

  const tin = methods.watch('tin');

  const { refetch, isFetching } = useFetchContractorByTin({ tin: Number(tin) });

  const getContractorData = async () => {
    const { data, error } = await refetch();
    if (error) setGlobalError(error);

    if (data) {
      setValue('tin', tin, { shouldDirty: false });

      if (data.legal_status) setValue('legalStatus', data.legal_status);
      setValue('companyName', data.name);

      if (data.city) {
        setValue('region', data.city.region);
        setValue('city', data.city);
      }

      if (data.city_district) setValue('cityDistrict', data.city_district);

      setValue('street', data.street_name);
      setValue('house', '0');
      setValue('postalCode', data.post_code);
      setValue('phoneNumber', rawPhoneNumberFormat(data.phone));
    }
  };

  return (
    <StyledWideFormRoot>
      <StyledFormProvider methods={methods} onSubmit={submitForm}>
        <StyledFormTitle>{t('forms.company-form.title')}</StyledFormTitle>
        <Box sx={{ mb: 5 }}>
          <CompanyInfoForm handleLoadData={getContractorData} isLoading={isFetching} />
        </Box>
        <Box sx={{ mb: 5 }}>
          <AddressForm />
        </Box>

        <StepsNavigation
          onBackwardClick={handleBackwardClick}
          backwardButtonProps={{ sx: { opacity: 0 } }}
          currentStep={currentStepIndex + 1}
          totalSteps={TOTAL_STEPS}
          forwardButtonProps={{
            type: 'submit',
            loading: isSubmitting,
          }}
        />

        <AutocompleteModal onChange={handleChooseOption} />
      </StyledFormProvider>
    </StyledWideFormRoot>
  );
};
