import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { ReactComponent as WarningIcon } from '@/shared/assets/icons-2.0/warning.svg';

import { styles } from './styles';

export const FooterText = () => {
  const { t } = useTranslation('privacy-policy');
  return (
    <Box sx={styles.footerWarningBox}>
      <WarningIcon />
      <Box sx={styles.footerWarningBoxText}>{t('modal.footer-text')}</Box>
    </Box>
  );
};
