import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Collapse, Link, Stack, Typography } from '@mui/material';
import { AxiosError, HttpStatusCode } from 'axios';
import i18next from 'i18next';
import { capitalize, isArray, map } from 'lodash';

import { ClipboardButton } from '@/components/buttons/ClipboardButton';
import {
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
} from '@/components/dialogs/elements.confirmation-dialog';
import { useGlobalErrorStore } from '@/components/dialogs/GlobalErrorDialog/useGlobalErrorStore';
import { SUPPORT_PHONE_CALLTO, SUPPORT_PHONE_FORMATTED } from '@/shared/constants/dorim.constants';
import { replaceIncorrectLineBreaks } from '@/shared/helpers/strings.helpers';

// ----------------------------------------------------------------------

export type ErrorDetailsProps = {
  url: string;
  status: string | number;
  data: Record<'message', string> | Record<'detail', string>;
};

// ----------------------------------------------------------------------

export const getErrorInfo = (error: unknown) => {
  let responseUrl: ErrorDetailsProps['url'];
  let responseStatus: ErrorDetailsProps['status'];
  let responseData: ErrorDetailsProps['data'];

  if (error instanceof AxiosError) {
    const { message: globalMessage, response } = error;

    responseUrl = response?.request?.responseURL ?? 'N/A';
    responseStatus = response?.status ?? HttpStatusCode.NotFound;
    responseData = response?.data
      ? isArray(response?.data?.detail)
        ? { message: globalMessage }
        : response?.data
      : { message: i18next.t('global-error-dialog.unknown-error', { ns: 'dialogs' }) };
  } else if (error instanceof Error) {
    const { message } = error;

    responseUrl = 'N/A';
    responseStatus = 'N/A';
    responseData = { message };
  } else {
    responseUrl = 'N/A';
    responseStatus = 'N/A';
    responseData = { message: i18next.t('global-error-dialog.unknown-error', { ns: 'dialogs' }) };
  }

  return { responseUrl, responseStatus, responseData };
};

// ----------------------------------------------------------------------

const ErrorDetails = (props: ErrorDetailsProps) => {
  const { url, status, data } = props;
  const { t } = useTranslation('dialogs');

  // ----------------------------------------------------------------------

  const [areDetailsVisible, setAreDetailsVisible] = useState(false);
  const toggleDetails = () => setAreDetailsVisible(isVisible => !isVisible);

  // ----------------------------------------------------------------------

  const details = useMemo(() => {
    const serializedDetailsEntries = map(
      {
        date: new Date().toLocaleString('ru-RU'),
        url,
        status,
        response: JSON.stringify(data, null, 2),
      },
      (value, key) => `${capitalize(key)}: ${value}`,
    );

    return serializedDetailsEntries.join('\n');
  }, [url, status, data]);

  // ----------------------------------------------------------------------

  return (
    <Stack spacing={1} width="100%">
      <Link component="button" variant="Body/large" color="secondary" underline="none" onClick={toggleDetails}>
        {!areDetailsVisible ? t('global-error-dialog.links.details') : t('global-error-dialog.links.hide')}
      </Link>

      <Collapse in={areDetailsVisible} sx={{ position: 'relative' }}>
        <Card
          component={Stack}
          spacing={0.5}
          sx={{
            width: '100%',
            textAlign: 'left',
            padding: theme => theme.spacing(2, 6, 2, 2),
            backgroundColor: theme => theme.palette.background.neutral,
            position: 'static',
            overflowX: 'auto',
          }}
        >
          <Typography sx={{ whiteSpace: 'pre' }}>{details}</Typography>

          <Box
            sx={{
              position: 'absolute',
              top: theme => theme.spacing(1),
              right: theme => theme.spacing(1),
            }}
          >
            <ClipboardButton valueToCopy={details} />
          </Box>
        </Card>
      </Collapse>
    </Stack>
  );
};

// ----------------------------------------------------------------------

export const GlobalErrorDialog = () => {
  const { globalError, setGlobalError } = useGlobalErrorStore();
  const { t } = useTranslation('dialogs');

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleReset = () => setGlobalError(null);

  useEffect(() => {
    if (globalError) {
      handleOpen();
    }
  }, [globalError]);

  // ----------------------------------------------------------------------

  const { responseUrl, responseStatus, responseData } = getErrorInfo(globalError);

  // ----------------------------------------------------------------------

  return (
    <ConfirmationDialogContainer
      open={isOpen}
      subject="error"
      maxWidth="sm"
      onClose={handleClose}
      TransitionProps={{ onExited: handleReset }}
      PaperProps={{
        'data-testid': 'default-error-dlg',
      }}
    >
      <ConfirmationDialogHeader whiteSpace="pre-wrap" sx={{ maxWidth: 350 }}>
        {replaceIncorrectLineBreaks(
          'message' in responseData
            ? responseData.message
            : 'detail' in responseData
              ? responseData.detail
              : t('global-error-dialog.unknown-error'),
        )}
      </ConfirmationDialogHeader>

      <ErrorDetails url={responseUrl} status={responseStatus} data={responseData} />

      <Typography color="neutral.700">
        {t('global-error-dialog.support-title')}{' '}
        <Link href={`tel:${SUPPORT_PHONE_CALLTO}`} color="inherit">
          {SUPPORT_PHONE_FORMATTED}
        </Link>
      </Typography>

      <ConfirmationDialogActions justifyContent="center">
        <ConfirmationDialogActionProceedButton
          autoFocus
          fullWidth={false}
          onClick={handleClose}
          data-testid="default-error-dlg-close-btn"
        >
          {t('global-error-dialog.actions.close')}
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
