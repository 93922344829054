import i18next from 'i18next';

import { Yup } from '@/shared/lib/yup';

export let passwordSchema: Yup.ObjectSchema<
  {
    password: string;
    passwordConfirmation: string;
  },
  Yup.AnyObject,
  {
    password: undefined;
    passwordConfirmation: undefined;
  },
  ''
>;

i18next.on('languageChanged', () => {
  passwordSchema = Yup.object().shape({
    password: Yup.string().required(
      i18next.t('components.password-form.password-schema.required', { ns: 'auth-page' }),
    ),
    passwordConfirmation: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        i18next.t('components.password-form.password-schema.password-confirmation', { ns: 'auth-page' }),
      )
      .required(i18next.t('components.password-form.password-schema.required', { ns: 'auth-page' })),
  });
});
