import { useCallback } from 'react';
import { zoomPlugin as useZoomPlugin, type RenderZoomInProps, type RenderZoomOutProps } from '@react-pdf-viewer/zoom';
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import { debounce } from 'lodash';

import { ToolbarButton } from './ToolbarButton';

export const useToolbarZoom = () => {
  const zoomPluginInstance = useZoomPlugin();
  const { ZoomIn, ZoomOut } = zoomPluginInstance;

  const ZoomInButton = useCallback(() => {
    return (
      <ZoomIn>
        {(props: RenderZoomInProps) => (
          <ToolbarButton
            onClick={debounce(() => {
              props.onClick();
            }, 100)}
          >
            <ZoomInIcon />
          </ToolbarButton>
        )}
      </ZoomIn>
    );
    /* eslint-disable-next-line */
  }, [zoomPluginInstance, ZoomIn]);

  const ZoomOutButton = useCallback(
    () => (
      <ZoomOut>
        {(props: RenderZoomOutProps) => (
          <ToolbarButton
            onClick={debounce(() => {
              props.onClick();
            }, 100)}
          >
            <ZoomOutIcon />
          </ToolbarButton>
        )}
      </ZoomOut>
    ),
    /* eslint-disable-next-line */
    [zoomPluginInstance, ZoomOut],
  );

  return {
    zoomPluginInstance,
    ZoomInButton,
    ZoomOutButton,
  };
};
