import { useTranslation } from 'react-i18next';

import { RHFCheckbox } from '@/components/form-controls';
import { OFFERS_LINK, PROCESSING_OF_PERSONAL_DATA_LINK } from '@/shared/constants/dorim.constants';

import { StyledLabel, StyledLink } from './styles';
import type { AgreementFieldProps } from './types';

export const AgreementField = ({ name }: AgreementFieldProps) => {
  const { t } = useTranslation('auth-sign-up');

  return (
    <RHFCheckbox
      name={name}
      label={
        <StyledLabel>
          {t('components.agreement-field.agreement')}{' '}
          <StyledLink color="inherit" href={OFFERS_LINK} target="_blank">
            {t('components.agreement-field.public-offer')}
          </StyledLink>
          ,{' '}
          <StyledLink color="inherit" href={PROCESSING_OF_PERSONAL_DATA_LINK} target="_blank">
            {t('components.agreement-field.processing-of-personal-data')}
          </StyledLink>
        </StyledLabel>
      }
      CheckboxProps={{
        color: 'secondary',
      }}
    />
  );
};
