import { useTranslation } from 'react-i18next';
import { CircularProgress, Stack, Typography } from '@mui/material';

import { SummaryBarContainer } from './SummaryBarContainer';

export const SummaryBarLoading = () => (
  <SummaryBarContainer justifyContent="center">
    <CircularProgress size="2em" color="secondary" />
  </SummaryBarContainer>
);

export type SummaryBarEmptyProps = React.PropsWithChildren;

export const SummaryBarEmpty = (props: SummaryBarEmptyProps) => {
  const { t } = useTranslation('cloud-cart');

  return (
    <SummaryBarContainer justifyContent={props.children ? 'space-between' : 'flex-end'}>
      <Typography variant="Label/large">{t('summary-bar.placeholder.empty')}</Typography>
      {props.children && (
        <Stack direction="row" spacing={1} alignItems="center">
          {props.children}
        </Stack>
      )}
    </SummaryBarContainer>
  );
};
