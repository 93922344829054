import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import HttpBackend, { type HttpBackendOptions } from 'i18next-http-backend';

export const applyGlobalConfigI18next = () =>
  i18next
    .use(initReactI18next)
    .use(HttpBackend)
    .init<HttpBackendOptions>({
      lng: 'ru',
      fallbackLng: 'ru',
      ns: [
        'avatar',
        'auth-page',
        'auth-reset-password',
        'auth-sign-in',
        'auth-sign-up',
        'account',
        'navbar',
        'dialogs',
        'cart',
        'cart-page',
        'checkout-page',
        'components',
        'status-constants',
        'clout-cart',
        'drug-info',
        'breadcrumbs',
        'actions',
        'privacy-policy',
        'form-controls',
        'working-day-types-constants',
        'create-telegram-group-page',
        'confirmation-dialog-elements',
        'specifications-root-page',
        'specifications-detail-page',
        'specifications-document-page',
        'specifications-notice-page',
        'specifications-main-contract',
        'specifications-contract',
        'specifications-marketing-org-page',
        'specifications-analytics-page',
        'product-selection-page',
        'product-selection-auto',
        'product-selection-bound-drugs',
        'product-selection-drug-list',
        'product-selection-settings',
        'product-selection-auto-binding-page',
        'product-selection-user-feedback-dialog',
        'product-selection-alphabetical-index',
        'support-menu-popover',
        'profile-popover',
        'dorim-promo-bonus-chart',
        'settings-page',
        'profile-page',
        'distributor-info-modal',
        'e-imzo-modal',
        'field-matching',
        'search-input',
        'license-holder-and-maker-info',
        'yup',
        'requirement-page',
        'requirements-calculation-page',
      ],

      backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
