import {
  DoneOutlined as DoneOutlinedIcon,
  WarningAmberOutlined as WarningAmberOutlinedIcon,
} from '@mui/icons-material';
import { Box } from '@mui/material';

import type { ValidationRuleProps } from '@/modules/account/PasswordStrengthValidation/ValidationRule';

type ValidationRuleIndicatorProps = Pick<ValidationRuleProps, 'order' | 'status'>;

export const ValidationRuleIndicator = (props: ValidationRuleIndicatorProps) => {
  const { order, status } = props;

  if (!status) {
    return (
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: '50%',
          border: '1px solid',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 12,
          fontWeight: 500,
          color: 'neutral.700',
        }}
      >
        {order}
      </Box>
    );
  }

  if (status === 'error') {
    return <WarningAmberOutlinedIcon fontSize="medium" color="warning" />;
  }

  return <DoneOutlinedIcon fontSize="medium" color="success" />;
};
