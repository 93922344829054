import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { Alert, Divider, Fab, Stack, Typography } from '@mui/material';

import type { UserContractor } from '@/api/domains/user-contractor.type';
import { formatPhoneNumber } from '@/shared/helpers/format.helpers';
import { ProfilePaths } from '@/shared/lib/react-router';

export type AgreementContractorContentProps = {
  isSigned: boolean;
  hasAgreementsData: boolean;
  hasDirectorData: boolean;
  agreementsData: UserContractor['agreements_data'];
  directorContact: UserContractor['director_contact'];
};

export const ContractorContent = (props: AgreementContractorContentProps) => {
  const { isSigned, hasAgreementsData, hasDirectorData, agreementsData, directorContact } = props;
  const { t } = useTranslation('specifications-document-page');

  const openEntityInfo = () => {
    window.open(ProfilePaths.ENTITY_INFO_PATH, '_blank');
  };

  const hasContractorData = hasAgreementsData && hasDirectorData;

  return (
    <Fragment>
      {!hasContractorData && !isSigned && (
        <Alert variant="outlined" severity="error">
          {t('document-contractor-details.contractor-content.alerts.no-contractor-data')}
        </Alert>
      )}

      {isSigned && (
        <Alert variant="outlined" severity="info">
          {t('document-contractor-details.contractor-content.alerts.is-signed')}
        </Alert>
      )}

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          {t('document-contractor-details.contractor-content.titles.bank-name')}
        </Typography>
        <Typography variant="Body/medium">{hasAgreementsData ? agreementsData?.bank_name : 'Не заполнено'}</Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          {t('document-contractor-details.contractor-content.titles.bank-mfo')}
        </Typography>
        <Typography variant="Body/medium">{hasAgreementsData ? agreementsData?.bank_mfo : 'Не заполнено'}</Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          {t('document-contractor-details.contractor-content.titles.bank-checking-account')}
        </Typography>
        <Typography variant="Body/medium">
          {hasAgreementsData
            ? agreementsData?.bank_checking_account
            : t('document-contractor-details.contractor-content.titles.no-director-data')}
        </Typography>
      </Stack>

      <Divider />

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          {t('document-contractor-details.contractor-content.titles.director')}
        </Typography>
        <Typography variant="Body/medium">
          {hasDirectorData
            ? directorContact?.name
            : t('document-contractor-details.contractor-content.titles.no-director-data')}
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          {t('document-contractor-details.contractor-content.titles.phone')}
        </Typography>
        <Typography variant="Body/medium">
          {hasDirectorData && directorContact?.phone_number
            ? formatPhoneNumber(directorContact.phone_number)
            : t('document-contractor-details.contractor-content.titles.no-director-data')}
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Typography variant="Body/small" color="grey.500">
          {t('document-contractor-details.contractor-content.titles.address-row')}
        </Typography>
        <Typography variant="Body/medium">
          {hasAgreementsData ? agreementsData?.address_row : 'Не заполнено'}
        </Typography>
      </Stack>

      <Fab
        variant="outlinedExtended"
        color="inherit"
        size="large"
        sx={{ alignSelf: 'flex-start' }}
        onClick={openEntityInfo}
      >
        <OpenInNewIcon />
        <Typography component="span" variant="button" color="secondary">
          {t('document-contractor-details.contractor-content.actions.edit')}
        </Typography>
      </Fab>
    </Fragment>
  );
};
