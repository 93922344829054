import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';

import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
} from '@/components/dialogs';
import { DEFAULT_DATE_FORMAT } from '@/shared/constants/date-formats.constants';
import { formatDateTime } from '@/shared/helpers/format.helpers';
import { useSpecification } from '@/shared/hooks/useSpecification';
import { DorimPromoPaths } from '@/shared/lib/react-router';

import { useSpecificationIdFromPathParams } from '../../../../../lib';
import { useSpecificationContextWindowsVisibility, useSpecificationStoreActions } from '../../../../lib';

export const MainContractSigningConfirmationDialog = () => {
  const specificationId = useSpecificationIdFromPathParams();
  const { data: specification } = useSpecification({ specificationId });
  const { t } = useTranslation('specifications-detail-page');

  const { isMainContractConfirmationDialogVisible } = useSpecificationContextWindowsVisibility();
  const { closeMainContractConfirmationDialog } = useSpecificationStoreActions();

  const navigate = useNavigate();
  const navigateToMainContractDocument = async () => {
    if (!specificationId) {
      return;
    }

    navigate(
      DorimPromoPaths.generateSpecificationDetailContractDocumentPath({
        specificationId: String(specificationId),
      }),
    );
  };

  if (!specification?.agreement) {
    return null;
  }

  const { start_date, end_date } = specification.agreement;

  return (
    <ConfirmationDialogContainer
      open={isMainContractConfirmationDialogVisible}
      subject="confirm"
      onClose={closeMainContractConfirmationDialog}
    >
      <ConfirmationDialogHeader>
        {t('dialogs.main-contract-signing-confirmation-dialog.title')}
      </ConfirmationDialogHeader>
      <Stack spacing={3}>
        <Typography variant="Body/medium" color="neutral.700">
          <Trans
            ns="specifications-detail-page"
            i18nKey="dialogs.main-contract-signing-confirmation-dialog.description"
            values={{
              period: `${formatDateTime(start_date, DEFAULT_DATE_FORMAT)}-${formatDateTime(end_date, DEFAULT_DATE_FORMAT)}`,
            }}
          />
        </Typography>
        <Typography variant="Body/medium" color="neutral.700">
          {t('dialogs.main-contract-signing-confirmation-dialog.payment-info')}
        </Typography>
      </Stack>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={closeMainContractConfirmationDialog} />
        <ConfirmationDialogActionProceedButton autoFocus onClick={navigateToMainContractDocument}>
          {t('dialogs.main-contract-signing-confirmation-dialog.actions.navigate-to-main-contract-document')}
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
