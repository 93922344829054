import { Trans } from 'react-i18next';

import { StyledIntroText, StyledLogotype, StyledRoot } from './styles';

export const Welcome = () => (
  <StyledRoot>
    <StyledLogotype />
    <StyledIntroText>
      <Trans ns="auth-page" i18nKey="components.welcome.intro-text" components={{ br: <br /> }} />
    </StyledIntroText>
  </StyledRoot>
);
