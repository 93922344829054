import { useTranslation } from 'react-i18next';
import { AxiosError, HttpStatusCode } from 'axios';

import type { SendOTPResponseBody } from '@/api/domains/auth.types';
import { VerifyPhone } from '@/pages/auth/components/VerifyPhone';
import { getExpiredTimestamp } from '@/pages/auth/helpers/getExpiredTimestamp';
import { useBan } from '@/pages/auth/sign-up/hooks/useBan';
import { useNavigation } from '@/pages/auth/sign-up/hooks/useNavigation';
import { useSignUpStoreUserData } from '@/pages/auth/sign-up/store/signUp.store';
import { useAuthMethods } from '@/shared/lib/auth';
import {
  useOTPStoreActions,
  useOTPStoreSignUpAttemptCounter,
  useOTPStoreSignUpSendData,
} from '@/shared/stores/otp.store';

export const VerifyPhoneForm = () => {
  const navigation = useNavigation();
  const storedUserData = useSignUpStoreUserData();
  const sendData = useOTPStoreSignUpSendData();
  const { signUpSendOTP, signUpVerifyOTP, signUpCheckIpLimits } = useAuthMethods();
  const {
    setSignUpVerifyData,
    setSignUpSendData,
    updateSignUpExpiredAt,
    incrementSignUpAttemptCounter,
    resetSignUpAttemptCounter,
  } = useOTPStoreActions();
  const attemptCounter = useOTPStoreSignUpAttemptCounter();
  const { t } = useTranslation('auth-sign-up');

  const { applyBan } = useBan();

  const onVerified = (token: string) => {
    resetSignUpAttemptCounter();
    setSignUpVerifyData({ signup_token: token });
    navigation.goToNextStep();
  };

  const onCreateOTP = ({ lifetime, sms_version }: SendOTPResponseBody) => {
    if (lifetime && sms_version) {
      setSignUpSendData({
        lifetime,
        expiredAt: getExpiredTimestamp(lifetime),
        sms_version,
      });
    }
  };

  const onFailedCreateOTP = async (lifetime: number) => {
    try {
      await signUpCheckIpLimits();
      updateSignUpExpiredAt(getExpiredTimestamp(lifetime));
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.TooManyRequests) {
          if (error.response?.headers['retry-after']) {
            applyBan(parseInt(error.response.headers['retry-after']));
          }
        }
      }
    }
  };

  const handleBeforeVerify = () => {
    incrementSignUpAttemptCounter();
  };
  const handleBeforeCreateOTP = () => {
    resetSignUpAttemptCounter();
  };

  return (
    <VerifyPhone
      title={t('forms.verify-phone-form.title')}
      attemptCounter={attemptCounter}
      applyBanFn={applyBan}
      verifyOtpApiMethod={signUpVerifyOTP}
      sendOtpApiMethod={signUpSendOTP}
      phoneNumber={storedUserData.phoneNumber}
      otpSendData={{
        smsVersion: sendData.sms_version,
        expiredAt: sendData.expiredAt,
      }}
      onCreateOTP={onCreateOTP}
      onFailedCreateOTP={onFailedCreateOTP}
      onVerified={onVerified}
      navigation={{
        goToNextStep: navigation.goToNextStep,
        goToPreviousStep: navigation.goToPreviousStep,
        currentStep: navigation.currentStepIndex + 1,
        totalSteps: navigation.totalSteps,
      }}
      beforeVerify={handleBeforeVerify}
      beforeCreateOTP={handleBeforeCreateOTP}
    />
  );
};
