import type { RequirementsCalculationDetailPathParams } from '@/shared/lib/react-router';
import {
  BreadcrumbLink,
  LazyBreadcrumb,
  RequirementsCalculationPaths,
  type RouteObjectWithBreadcrumbs,
} from '@/shared/lib/react-router';

import { useRequirement } from './useRequirement.ts';

export const RequirementPageRoute: RouteObjectWithBreadcrumbs = {
  path: RequirementsCalculationPaths.REQUIREMENTS_CALCULATION_DETAIL_PATH,
  handle: {
    breadcrumb: (params: RequirementsCalculationDetailPathParams) => ({
      Component: () => {
        const { data: requirement, isLoading } = useRequirement({ dc_id: Number(params.dcId) });

        return (
          <LazyBreadcrumb isLoading={isLoading}>
            <BreadcrumbLink to={RequirementsCalculationPaths.generateRequirementPath(params)}>
              {requirement?.pharmacy_full_name}
            </BreadcrumbLink>
          </LazyBreadcrumb>
        );
      },
    }),
  },
  lazy: async () => {
    const { RequirementPage } = await import(
      /* webpackChunkName: "RequirementPage" */
      './ui/RequirementPage.tsx'
    );

    return {
      Component: () => <RequirementPage />,
    };
  },
};
