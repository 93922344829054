import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Card } from '@mui/material';

import { NavigateConfirmationDialog } from '@/components/dialogs';
import { BaseLayout } from '@/layouts/BaseLayout';
import { Header } from '@/layouts/Header';
import { PageContainer, PageContent } from '@/layouts/Page';

import { ProfileTabs } from './ProfileTabs';

export const ProfileLayout = () => {
  const { t } = useTranslation('profile-page');

  return (
    <Fragment>
      <BaseLayout>
        <PageContainer title={t('layout.titles.page-container')}>
          <Header title={t('layout.titles.header')} />
          <ProfileTabs />
          <PageContent>
            <Card variant="outlined" sx={{ flexGrow: 1 }}>
              <Outlet />
            </Card>
          </PageContent>
        </PageContainer>
      </BaseLayout>

      <NavigateConfirmationDialog />
    </Fragment>
  );
};
