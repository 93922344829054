import { useTranslation } from 'react-i18next';
import { Card, Stack, styled, Typography } from '@mui/material';

import { ContractorContent } from './ContractorContent';
import { ContractorSkeleton } from './ContractorSkeleton';
import { useContractorDetail } from './useContractorDetail';

const StyledRoot = styled(Card)({
  width: 400,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}) as typeof Card;

const StyledHeader = styled('div')(({ theme }) => ({
  flexShrink: 0,
  display: 'flex',
  height: 48,
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  borderBottom: theme.shape.divider,
}));

export type ContractorDetailsProps = {
  isSigned?: boolean;
};

export const ContractorDetail = (props: ContractorDetailsProps) => {
  const { isSigned = false } = props;

  const {
    hasAgreementsData,
    hasDirectorData,
    agreementsData,
    directorContact,
    query: { isFetching, isError },
  } = useContractorDetail();
  const { t } = useTranslation('specifications-document-page');

  return (
    <StyledRoot variant="outlined">
      <StyledHeader>
        <Typography variant="Body/large" fontWeight={500}>
          {t('document-contractor-details.bank-info')}
        </Typography>
      </StyledHeader>

      {!isError && (
        <Stack spacing={3} p={3} flexGrow={1}>
          {isFetching && <ContractorSkeleton />}
          {!isFetching && (
            <ContractorContent
              isSigned={isSigned}
              hasAgreementsData={hasAgreementsData}
              hasDirectorData={hasDirectorData}
              agreementsData={agreementsData}
              directorContact={directorContact}
            />
          )}
        </Stack>
      )}
    </StyledRoot>
  );
};
