import { paramsToQueryKey } from '@/shared/lib/react-query';

import type {
  FetchRequirementParams,
  FetchRequirementsCalculationParams,
  FetchRequirementSettingsParams,
} from './requirements-calculation.api';

const PREFIX = 'requirements-calculation';

export const RequirementsCalculationQueryKeys = {
  getRootKey: () => [PREFIX],

  getRequirementsCalculationKey: (params: Partial<FetchRequirementsCalculationParams>) =>
    paramsToQueryKey([PREFIX, 'requirements-calculation'], params),

  getRequirementKey: (params: Partial<FetchRequirementParams>) => paramsToQueryKey([PREFIX, 'requirement'], params),

  getRequirementSettingsKey: (params: Partial<FetchRequirementSettingsParams>) =>
    paramsToQueryKey([PREFIX, 'requirement-settings'], params),
} as const;
