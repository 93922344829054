import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';

import type { Contractor } from '@/modules/profile/types';

import { EntityInfoItem } from './EntityInfoItem';
import { EntityInfoItemAddress } from './EntityInfoItemAddress';
import { EntityInfoItemContent } from './EntityInfoItemContent';

type EntityInfoContentProps = {
  contractor: Contractor;
};

export const EntityInfoContent = (props: EntityInfoContentProps) => {
  const { contractor } = props;
  const { children: contractorPharmacies } = contractor;
  const { t } = useTranslation('profile-page');

  return (
    <Fragment>
      {contractor.name && (
        <Grid xs={4}>
          <EntityInfoItem title={t('entity-info.entity-info-content.titles.entity')}>
            <EntityInfoItemContent
              title={t('entity-info.entity-info-content.titles.name')}
              description={contractor.name}
            />
            {contractor.address && (
              <EntityInfoItemAddress
                title={t('entity-info.entity-info-content.titles.address')}
                address={contractor.address}
              />
            )}
            {contractor.tin > 0 && (
              <EntityInfoItemContent
                title={t('entity-info.entity-info-content.titles.tin')}
                description={contractor.tin}
              />
            )}
          </EntityInfoItem>
        </Grid>
      )}

      {contractorPharmacies?.map(
        pharmacy =>
          pharmacy.name && (
            <Grid key={pharmacy.id} xs={4}>
              <EntityInfoItem title={t('entity-info.entity-info-content.titles.pharmacy')}>
                <EntityInfoItemContent
                  title={t('entity-info.entity-info-content.titles.name')}
                  description={pharmacy.name}
                />
                {pharmacy.address && (
                  <EntityInfoItemAddress
                    title={t('entity-info.entity-info-content.titles.address')}
                    address={pharmacy.address}
                  />
                )}
              </EntityInfoItem>
            </Grid>
          ),
      )}
    </Fragment>
  );
};
