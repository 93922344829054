import i18next from 'i18next';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

let isPossibleEmailAddressErrorMessage: string;
i18next.on('languageChanged', () => {
  isPossibleEmailAddressErrorMessage = i18next.t('is-possible-email-address', {
    ns: 'yup',
  });
});

Yup.addMethod(Yup.string, 'isPossibleEmailAddress', function (message?: string) {
  return this.test('test-email-address', (value, testContext) => {
    const { createError } = testContext;

    if (!value) {
      return true;
    }

    const isValidEmailAddress = EMAIL_REGEX.test(value);

    return isValidEmailAddress ? true : createError({ message: message ?? isPossibleEmailAddressErrorMessage });
  });
});

// ----------------------------------------------------------------------

Yup.addMethod(Yup.string, 'isPossiblePhoneNumber', function (message?: string) {
  return this.test('test-phone-number', (value, testContext) => {
    const { createError } = testContext;

    if (!value) {
      return true;
    }

    return isPossiblePhoneNumber(`+${value}`)
      ? true
      : createError({ message: message ?? 'Введите корректный номер телефона' });
  });
});

// ----------------------------------------------------------------------

export { Yup };
