import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { every, some } from 'lodash';

import type { LabelProps } from '@/components/labels';
import { Label } from '@/components/labels';

import type { PasswordStrengthValidationProps } from './PasswordStrengthValidation';

type ValidationBadgeProps = Pick<PasswordStrengthValidationProps, 'validationResult'>;

const StatusBadge = forwardRef<HTMLSpanElement, LabelProps>((props, ref) => (
  <Label
    variant="soft"
    sx={{ width: 'fit-content', textTransform: 'none' }}
    {...props}
    data-testid="password-validation-status"
    ref={ref}
  />
));

export const ValidationStatusBadge = (props: ValidationBadgeProps) => {
  const { validationResult } = props;
  const { t } = useTranslation('account');

  const hasAnyError = some(validationResult, status => status === 'error');

  if (hasAnyError) {
    return (
      <StatusBadge color="warning">{t('password-strength-validation.validation-status-badge.any-error')}</StatusBadge>
    );
  }

  const hasPassedValidation = every(validationResult, status => status === 'success');

  if (hasPassedValidation) {
    return (
      <StatusBadge color="success">
        {t('password-strength-validation.validation-status-badge.passed-validation')}
      </StatusBadge>
    );
  }

  return <StatusBadge>{t('password-strength-validation.validation-status-badge.no-password')}</StatusBadge>;
};
