import { httpClient } from '@/shared/lib/axios';

import type { PaginatedList, PaginationParams } from '../types/pagination.types';
import type { Requirement, RequirementRow, RequirementSettings } from './requirements-calculation.types';

export type FetchRequirementsCalculationParams = PaginationParams & {
  date_from?: string;
  date_to?: string;
  query?: string;
};

export type FetchRequirementParams = {
  dc_id: number;
};

export type CreateRequirementParams = {
  pharmacy_id: number;
};

export type UpdateDcRowParams = {
  dc_id: number;
  row_id: number;
  to_order: number;
};

export type FetchRequirementSettingsParams = {
  pharmacy_id: number;
};

export type CreateRequirementSettingsParams = {
  pharmacy_id: number;
} & RequirementSettings;

export type ResetRowsParams = {
  dc_id: number;
};

export type RestartRequirementParams = {
  dc_id: number;
};

export const RequirementsCalculationApi = {
  fetchRequirementsCalculation: async (params: FetchRequirementsCalculationParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Requirement>>(`demand-calculation`, {
      params,
    });

    return data;
  },

  fetchRequirementsCalculationToDispatch: async (params: FetchRequirementsCalculationParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Requirement>>(`demand-calculation`, {
      params,
    });

    return data;
  },

  fetchRequirement: async (params: FetchRequirementParams) => {
    const { data } = await httpClient.get<Requirement>(`demand-calculation/${params.dc_id}`);
    return data;
  },

  createRequirement: async (params: CreateRequirementParams) => {
    const { data } = await httpClient.post<Requirement>('demand-calculation', params);

    return data;
  },

  updateRow: async ({ dc_id, row_id, ...params }: UpdateDcRowParams) => {
    const { data } = await httpClient.put<RequirementRow>(`demand-calculation/${dc_id}/rows/${row_id}`, params);

    return data;
  },

  resetRows: async (params: ResetRowsParams) => {
    const { data } = await httpClient.put<string>(`demand-calculation/${params.dc_id}/reset-rows`);
    return data;
  },

  restartRequirement: async (params: RestartRequirementParams) => {
    const { data } = await httpClient.post<string>(`demand-calculation/${params.dc_id}/restart`);
    return data;
  },

  fetchRequirementSettings: async (params: FetchRequirementSettingsParams) => {
    const { data } = await httpClient.get<RequirementSettings>(`demand-calculation/${params.pharmacy_id}/settings`);
    return data;
  },

  createRequirementSettings: async ({ pharmacy_id, ...params }: CreateRequirementSettingsParams) => {
    const { data } = await httpClient.post<RequirementSettings>(`demand-calculation/${pharmacy_id}/settings`, params);

    return data;
  },
};
