import { Trans } from 'react-i18next';

import { AuthGuard } from '@/guards/AuthGuard.tsx';
import { MainLayout } from '@/layouts/MainLayout.tsx';
import {
  BreadcrumbLink,
  RequirementsCalculationPaths,
  type RouteObjectWithBreadcrumbs,
} from '@/shared/lib/react-router';

import { RequirementPageRoute } from './requirement';

export const RequirementCalculationRoute: RouteObjectWithBreadcrumbs = {
  path: RequirementsCalculationPaths.ROOT_PATH,
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: RequirementsCalculationPaths.ROOT_PATH,
      handle: {
        breadcrumb: {
          element: (
            <BreadcrumbLink to={RequirementsCalculationPaths.ROOT_PATH}>
              <Trans ns="breadcrumbs" i18nKey="requirements_calculation.root" />
            </BreadcrumbLink>
          ),
        },
      },
      children: [
        {
          path: RequirementsCalculationPaths.ROOT_PATH,
          lazy: async () => {
            const { RequirementsCalculationPage } = await import(
              /* webpackChunkName: "RequirementsCalculationPage" */
              './ui/RequirementsCalculationPage.tsx'
            );

            return {
              Component: () => <RequirementsCalculationPage />,
            };
          },
        },
        RequirementPageRoute,
      ],
    },
  ],
};
