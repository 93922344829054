import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useMount, useNetworkState } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { isEmpty, isEqual, omit, pick } from 'lodash';
import { useSnackbar } from 'notistack';

import { ConfirmationWarningDialog, useGlobalErrorStore } from '@/components/dialogs';
import { getErrorInfo } from '@/components/dialogs/GlobalErrorDialog/GlobalErrorDialog';
import { StepsNavigation } from '@/pages/auth/components/StepsNavigation';
import { StyledFormProvider, StyledFormTitle, StyledWideFormRoot } from '@/pages/auth/components/styles';
import { FIRST_STEP, TOTAL_STEPS } from '@/pages/auth/sign-up/constants';
import type { AddressFormFields } from '@/pages/auth/sign-up/forms/AddressForm/AddressForm';
import { AddressForm, getDefaultAddressFormValues } from '@/pages/auth/sign-up/forms/AddressForm/AddressForm';
import { useClearSignUpData } from '@/pages/auth/sign-up/hooks/useClearSignUpData';
import { useNavigation } from '@/pages/auth/sign-up/hooks/useNavigation';
import { AutocompleteModal } from '@/pages/auth/sign-up/modals/AutocompleteModal';
import {
  useAutocompleteModalStoreActions,
  useAutocompleteModalStoreFieldName,
} from '@/pages/auth/sign-up/store/autocompleteModal.store';
import type { SignUpPharmacy } from '@/pages/auth/sign-up/store/signUp.store';
import { useSignUpStoreActions, useSignUpStoreState } from '@/pages/auth/sign-up/store/signUp.store';
import { useAuthMethods } from '@/shared/lib/auth';
import { AuthPaths } from '@/shared/lib/react-router';
import { useOTPStoreSignUpVerifyData } from '@/shared/stores/otp.store';

import { pharmacyFormSchema } from '../validation-schemas';
import type { PharmacyFormFields } from './components/PharmacyInfoForm';
import { getDefaultPharmacyFormValues, PharmacyInfoForm } from './components/PharmacyInfoForm';
import type { WarningType } from './data/warningMessage';
import { warningMessage } from './data/warningMessage';
import { isFormFilled } from './helpers/form';
import { getRequestBody } from './helpers/network';

type FormValues = PharmacyFormFields & AddressFormFields;
type FormFieldsKeys = keyof FormValues;
type FormFieldsValues = FormValues[FormFieldsKeys];

// ----------------------------------------------------------------------

export const PharmacyForm = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { online } = useNetworkState();

  const { goToPreviousStep, currentStepIndex, goToStep } = useNavigation();
  const navigate = useNavigate();

  const { pharmacyData: storedPharmacyData, userData, companyData } = useSignUpStoreState();
  const { setPharmacyData } = useSignUpStoreActions();
  const verifyData = useOTPStoreSignUpVerifyData();
  const { signUp } = useAuthMethods();
  const clearSignUpData = useClearSignUpData();
  const { t } = useTranslation('auth-sign-up');

  const [isFillButtonDisabled, setIsFillButtonDisabled] = useState(false);
  const { setGlobalError } = useGlobalErrorStore();
  const [isConfirmationEditDialogOpen, setIsConfirmationEditDialogOpen] = useState(false);
  const [typeOfWarning, setTypeOfWarning] = useState<WarningType>('default');

  const selectedFieldName = useAutocompleteModalStoreFieldName();
  const { closeAutocompleteModal } = useAutocompleteModalStoreActions();

  useEffect(() => {
    const ENQUEUE_KEY = 'no-internet';
    if (!online) {
      enqueueSnackbar(t('forms.pharmacy-form.snackbars.no-internet'), {
        variant: 'error',
        key: ENQUEUE_KEY,
        autoHideDuration: null,
      });
    } else {
      closeSnackbar(ENQUEUE_KEY);
    }
    /* eslint-disable-next-line */
  }, [online]);

  useMount(() => {
    window.scrollTo(0, 0);
  });

  const { phoneNumber, firstName, lastName, password } = userData;
  const { signup_token } = verifyData;
  const shouldPrefillForm = !!storedPharmacyData;
  const userPhone = phoneNumber ? phoneNumber : '';

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(pharmacyFormSchema),
    defaultValues: {
      ...getDefaultPharmacyFormValues(shouldPrefillForm, storedPharmacyData),
      ...getDefaultAddressFormValues(shouldPrefillForm, storedPharmacyData, userPhone),
    },
  });

  const {
    getValues,
    setValue,
    reset,
    formState: { isDirty, isSubmitting, dirtyFields },
  } = methods;

  const isOnlyNameChanged = isEqual(dirtyFields, { name: true });
  const isDirtyFieldsEmpty = isEmpty(dirtyFields);
  if (isDirty && !isOnlyNameChanged && !isDirtyFieldsEmpty && isFillButtonDisabled) {
    setIsFillButtonDisabled(false);
  }

  // ----------------------------------------------------------------------

  const submitForm = async (formData: FormValues) => {
    try {
      const pharmacyData: SignUpPharmacy = {
        name: formData.name,
        region: formData.region,
        city: formData.city,
        cityDistrict: formData.cityDistrict,
        streetType: formData.streetType,
        street: formData.street,
        house: formData.house,
        building: formData.building,
        apartment: formData.apartment,
        phoneNumber: formData.phoneNumber,
        postalCode: formData.postalCode,
      };

      setPharmacyData(pharmacyData);

      const requestBody = getRequestBody({
        pharmacyData,
        companyData,
        firstName,
        lastName,
        signupToken: signup_token,
        password,
      });

      const { access_token, refresh_token } = await signUp(requestBody);

      navigate(AuthPaths.SIGN_UP.FINAL, {
        state: { access_token, refresh_token },
      });
    } catch (error) {
      const { responseStatus } = getErrorInfo(error);

      // Error: bad signup token - go to the first step
      if (responseStatus === HttpStatusCode.BadRequest) {
        setTypeOfWarning('signupToken');
        setIsConfirmationEditDialogOpen(true);
      } else {
        setGlobalError(error);
      }
    }
  };

  const handleCloseDialog = () => {
    setIsConfirmationEditDialogOpen(false);
  };

  const fillForm = () => {
    const neededInfo = omit(companyData, ['companyName', 'legalStatus', 'tin']);

    reset({
      ...neededInfo,
      name: getValues('name'),
      phoneNumber: companyData.phoneNumber ? companyData.phoneNumber : undefined,
      postalCode: companyData.postalCode ? companyData.postalCode : undefined,
    });

    setIsFillButtonDisabled(true);
  };

  const handleChooseOption = (_: React.SyntheticEvent, value: FormFieldsValues) => {
    setValue(selectedFieldName as FormFieldsKeys, value, { shouldDirty: true });
    closeAutocompleteModal();
  };

  const handleProceedDialog = () => {
    if (typeOfWarning === 'address') {
      fillForm();
    } else if (typeOfWarning === 'signupToken') {
      clearSignUpData();
      goToStep(FIRST_STEP);
    }

    setIsConfirmationEditDialogOpen(false);
  };

  const handleFillForm = () => {
    const formValues = getValues();

    const importantFields = ['apartment', 'building', 'city', 'house', 'postalCode', 'region', 'street', 'streetType'];
    const neededPharmacyValues = pick(formValues, importantFields);
    const neededCompanyValues = pick(companyData, importantFields);

    const hasFilledFields = isFormFilled(neededPharmacyValues);
    const isPhoneDifferent =
      formValues.phoneNumber !== phoneNumber && formValues.phoneNumber !== companyData.phoneNumber;
    const isFieldsValuesEqual = isEqual(neededPharmacyValues, neededCompanyValues);

    const shouldShowWarning = (hasFilledFields && !isFieldsValuesEqual) || (phoneNumber && isPhoneDifferent);

    if (shouldShowWarning) {
      setTypeOfWarning('address');
      setIsConfirmationEditDialogOpen(true);
    } else {
      fillForm();
    }
  };

  const handleBackwardClick = () => {
    goToPreviousStep();
  };

  // ----------------------------------------------------------------------

  return (
    <StyledWideFormRoot>
      <StyledFormProvider methods={methods} onSubmit={submitForm}>
        <StyledFormTitle>{t('forms.pharmacy-form.title')}</StyledFormTitle>
        <Box sx={{ mb: 5 }}>
          <PharmacyInfoForm onFillForm={handleFillForm} isFillButtonDisabled={isFillButtonDisabled} />
        </Box>
        <Box sx={{ mb: 5 }}>
          <AddressForm />
        </Box>

        <StepsNavigation
          onBackwardClick={handleBackwardClick}
          currentStep={currentStepIndex + 1}
          totalSteps={TOTAL_STEPS}
          forwardButtonProps={{
            type: 'submit',
            loading: isSubmitting,
            disabled: !online,
          }}
        />

        <AutocompleteModal onChange={handleChooseOption} />
      </StyledFormProvider>

      <ConfirmationWarningDialog
        isLoading={false}
        open={isConfirmationEditDialogOpen}
        onClose={handleCloseDialog}
        onProceed={handleProceedDialog}
        titleText={warningMessage[typeOfWarning].headerText}
        contentText={warningMessage[typeOfWarning].bodyText}
        actionButtonText={t('forms.pharmacy-form.dialogs.confirmation-warning-dialog.action-button-text')}
      />
    </StyledWideFormRoot>
  );
};
