import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, Stack } from '@mui/material';
import { AxiosError, HttpStatusCode } from 'axios';

import { useGlobalErrorStore } from '@/components/dialogs';
import { CustomFormLabel, RHFMaskedPhoneTextField, RHFTextField } from '@/components/form-controls';
import { StepsNavigation } from '@/pages/auth/components/StepsNavigation';
import {
  StyledFormDescription,
  StyledFormProvider,
  StyledFormRoot,
  StyledFormTitle,
} from '@/pages/auth/components/styles';
import { useSendOTP } from '@/pages/auth/components/VerifyPhone/hooks/useSendOTP';
import { getExpiredTimestamp } from '@/pages/auth/helpers/getExpiredTimestamp';
import { AgreementField } from '@/pages/auth/sign-up/components/AgreementField';
import { TOTAL_STEPS } from '@/pages/auth/sign-up/constants';
import { useBan } from '@/pages/auth/sign-up/hooks/useBan';
import { useNavigation } from '@/pages/auth/sign-up/hooks/useNavigation';
import { useSignUpStoreActions, useSignUpStoreUserData } from '@/pages/auth/sign-up/store/signUp.store';
import { LANDING_PAGE_LINK } from '@/shared/constants/dorim.constants';
import { useAuthMethods } from '@/shared/lib/auth';
import { SignInLinks } from '@/shared/lib/react-router';
import { useOTPStoreActions } from '@/shared/stores/otp.store';

import { userSchema } from '../validation-schemas';
import type { FormValues } from './types';

const ALREADY_IN_USE_ERROR_TYPE = 'phoneAlreadyInUse';

export const RegistrationForm = () => {
  const { goToNextStep, currentStepIndex } = useNavigation();

  const { setUserData } = useSignUpStoreActions();
  const storedUserData = useSignUpStoreUserData();
  const { setSignUpSendData } = useOTPStoreActions();
  const { applyBan, resetBan } = useBan();
  const { signUpSendOTP, signUpCheckIpLimits } = useAuthMethods();
  const { resetSignUpAttemptCounter, updateSignUpExpiredAt } = useOTPStoreActions();
  const { t } = useTranslation('auth-sign-up');

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(userSchema),
    defaultValues: {
      firstName: storedUserData.firstName,
      lastName: storedUserData.lastName,
      phoneNumber: storedUserData.phoneNumber,
      isAgree: storedUserData.isAgree,
    },
  });

  const {
    formState: { isSubmitting, isSubmitSuccessful, errors },
    setError,
  } = methods;

  const { setGlobalError } = useGlobalErrorStore();

  const checkIpLimits = async (lifetime: number) => {
    try {
      await signUpCheckIpLimits();
      updateSignUpExpiredAt(getExpiredTimestamp(lifetime));
      goToNextStep();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.TooManyRequests) {
          if (error.response?.headers['retry-after']) {
            applyBan(parseInt(error.response.headers['retry-after']));
          }
        }
      }
    }
  };

  const sendOTP = useSendOTP({
    sendOtpApiMethod: signUpSendOTP,
    onSuccess({ lifetime, sms_version }) {
      resetBan();
      setSignUpSendData({
        lifetime,
        expiredAt: getExpiredTimestamp(lifetime),
        sms_version,
      });
    },
  });

  const submitForm = async (formData: FormValues) => {
    try {
      const { phoneNumber } = formData;

      await sendOTP({ phone: phoneNumber });

      if (phoneNumber !== storedUserData.phoneNumber) {
        resetSignUpAttemptCounter();
      }

      setUserData({
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        isAgree: formData.isAgree,
      });

      goToNextStep();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.TooManyRequests) {
          if (error.response?.headers['retry-after']) {
            checkIpLimits(parseInt(error.response.headers['retry-after']));
            return;
          }
        }

        if (error.response?.data?.message) {
          setError('phoneNumber', {
            message: error.response.data.message,
            type: ALREADY_IN_USE_ERROR_TYPE,
          });
          return;
        }
      } else {
        setGlobalError(error);
      }
    }
  };

  const onBackwardClick = () => {
    window.location.href = LANDING_PAGE_LINK;
  };

  const isFormLoading = isSubmitting || isSubmitSuccessful;
  const phoneAlreadyInUse = errors.phoneNumber?.type === ALREADY_IN_USE_ERROR_TYPE;
  return (
    <StyledFormRoot>
      <StyledFormTitle>{t('forms.registration-form.title')}</StyledFormTitle>
      <StyledFormDescription>{t('forms.registration-form.registration-description')}</StyledFormDescription>
      <StyledFormProvider methods={methods} onSubmit={submitForm}>
        <Stack spacing={3} mb={7}>
          <FormControl fullWidth>
            <CustomFormLabel htmlFor="firstName" isRequired>
              {t('forms.registration-form.labels.first-name')}
            </CustomFormLabel>
            <RHFTextField fullWidth name="firstName" id="firstName" variant="filled" />
          </FormControl>

          <FormControl fullWidth>
            <CustomFormLabel htmlFor="lastName" isRequired>
              {t('forms.registration-form.labels.last-name')}
            </CustomFormLabel>
            <RHFTextField fullWidth name="lastName" id="lastName" variant="filled" />
          </FormControl>

          <FormControl fullWidth>
            <CustomFormLabel htmlFor="phoneNumber" isRequired>
              {t('forms.registration-form.labels.phone-number')}
            </CustomFormLabel>
            <RHFMaskedPhoneTextField fullWidth name="phoneNumber" id="phoneNumber" variant="filled" />
          </FormControl>
          <AgreementField name="isAgree" />
        </Stack>

        {phoneAlreadyInUse ? (
          <Link to={SignInLinks.root}>
            <Button fullWidth variant="contained" color="secondary">
              {t('forms.registration-form.actions.sign-in')}
            </Button>
          </Link>
        ) : (
          <StepsNavigation
            onBackwardClick={onBackwardClick}
            currentStep={currentStepIndex + 1}
            totalSteps={TOTAL_STEPS}
            backwardButtonText={t('forms.registration-form.actions.go-to-main-page')}
            forwardButtonProps={{
              type: 'submit',
              loading: isFormLoading,
            }}
          />
        )}
      </StyledFormProvider>
    </StyledFormRoot>
  );
};
