import { Trans, useTranslation } from 'react-i18next';
import type { ButtonProps } from '@mui/material';
import { Button } from '@mui/material';

type CountdownButtonProps = ButtonProps & {
  totalSeconds: number;
  minutes: number;
  isExpired: boolean;
};

export const CountdownButton = (props: CountdownButtonProps) => {
  const { totalSeconds, minutes, isExpired, ...restOfProps } = props;
  const { t } = useTranslation('auth-page');

  const shouldShowSeconds = totalSeconds <= 90;

  return (
    <Button fullWidth variant="outlined" color="secondary" disabled={!isExpired} {...restOfProps}>
      {!isExpired && (
        <Trans
          ns="auth-page"
          i18nKey="components.verify-phone.countdown-button.resend-after"
          values={{ time: `${shouldShowSeconds ? `${totalSeconds} с` : `${minutes} мин`}` }}
        />
      )}
      {isExpired && t('components.verify-phone.countdown-button.resend')}
    </Button>
  );
};
