import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { StyledButton, StyledFormNavigation } from './styles';
import type { StepsNavigationProps } from './types';

export const StepsNavigation = (props: StepsNavigationProps) => {
  const { t } = useTranslation('auth-page');
  const {
    onBackwardClick,
    onForwardClick,
    forwardButtonProps,
    backwardButtonProps,
    backwardButtonText = t('components.steps-navigation.backward-button-text'),
    forwardButtonText = t('components.steps-navigation.forward-button-text'),
    totalSteps,
    currentStep,
  } = props;

  return (
    <StyledFormNavigation>
      <StyledButton onClick={onBackwardClick} variant="outlined" color="inherit" {...backwardButtonProps}>
        {backwardButtonText}
      </StyledButton>
      <Typography>
        <Trans ns="auth-page" i18nKey="components.steps-navigation.step" values={{ currentStep, totalSteps }} />
      </Typography>
      <StyledButton onClick={onForwardClick} variant="contained" color="secondary" {...forwardButtonProps}>
        {forwardButtonText}
      </StyledButton>
    </StyledFormNavigation>
  );
};
