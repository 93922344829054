import i18next from 'i18next';

export let rulesConfig: Array<{ id: number; label: string; test: (password: string) => boolean }> = [];

i18next.on('languageChanged', () => {
  rulesConfig = [
    {
      id: 1,
      label: i18next.t('rules-config.rule-1', { ns: 'account' }),
      test: (password: string) => /[a-z]/.test(password),
    },
    {
      id: 2,
      label: i18next.t('rules-config.rule-2', { ns: 'account' }),
      test: (password: string) => /[A-Z]/.test(password),
    },
    {
      id: 3,
      label: i18next.t('rules-config.rule-3', { ns: 'account' }),
      test: (password: string) => /[0-9]/.test(password),
    },
    {
      id: 4,
      label: i18next.t('rules-config.rule-4', { ns: 'account' }),
      test: (password: string) => password.length >= 8,
    },
  ];
});
