import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import {
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
} from '@/components/dialogs';
import { formatNumberByThousands } from '@/shared/helpers/format.helpers';
import { useSpecification } from '@/shared/hooks/useSpecification';
import { DorimPromoPaths, type SpecificationDetailPathParams } from '@/shared/lib/react-router';

import { useSpecificationContextWindowsVisibility, useSpecificationStoreActions } from '../../../../lib';

export const ContractClosingDialog = () => {
  const params = useParams<SpecificationDetailPathParams>();
  const { data } = useSpecification({ specificationId: Number(params.specificationId) });
  const { t } = useTranslation('specifications-detail-page');
  const { agreement } = data || {};

  const { isContractClosingDialogVisible } = useSpecificationContextWindowsVisibility();
  const { closeContractClosingDialog } = useSpecificationStoreActions();

  const navigate = useNavigate();
  const handleProceed = () => {
    closeContractClosingDialog();
    navigate(DorimPromoPaths.SPECIFICATIONS_ROOT_PATH, { replace: true });
  };

  return (
    <ConfirmationDialogContainer open={isContractClosingDialogVisible} subject="confirm">
      <ConfirmationDialogHeader>{t('dialogs.contract-closing-dialog.title')}</ConfirmationDialogHeader>
      {agreement && (
        <ConfirmationDialogBodyMutedText>
          {t('dialogs.contract-closing-dialog.description.payment')}{' '}
          <Typography component="span" variant="inherit" color="text.primary">
            <Typography component="span" variant="inherit" fontWeight={600}>
              {formatNumberByThousands(agreement.cashback_sum)}
            </Typography>{' '}
            <Typography component="span" variant="inherit">
              UZS
            </Typography>
          </Typography>{' '}
          <Trans
            ns="specifications-detail-page"
            i18nKey="dialogs.contract-closing-dialog.description.specification-confirmed"
            values={{ documentNumber: agreement.document_number }}
          />
        </ConfirmationDialogBodyMutedText>
      )}
      <ConfirmationDialogActions>
        <ConfirmationDialogActionProceedButton autoFocus onClick={handleProceed}>
          {t('dialogs.contract-closing-dialog.actions.navigate-to-contract-document')}{' '}
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
