import { Fragment } from 'react';
import { Trans } from 'react-i18next';

import { DocumentToolbarButton, type DocumentToolbarButtonProps } from '@/components/DocumentToolbar';
import { CASHBACK_STATUS_NOT_CONFIRMED, type CashbackStatusId } from '@/shared/constants/cashback-status.constants';
import { DorimPromoPaths, RouterLink } from '@/shared/lib/react-router';

import { AcceptContractButton } from './ui';

const getDocumentButtonText = (recordStatusId: CashbackStatusId) => {
  switch (recordStatusId) {
    case CASHBACK_STATUS_NOT_CONFIRMED:
      return <Trans ns="specifications-detail-page" i18nKey="document-toolbar.actions.read" />;
    default:
      return <Trans ns="specifications-detail-page" i18nKey="document-toolbar.actions.specification" />;
  }
};

const getDocumentButtonVariant = (recordStatusId: CashbackStatusId): DocumentToolbarButtonProps['variant'] => {
  switch (recordStatusId) {
    case CASHBACK_STATUS_NOT_CONFIRMED:
      return 'contained';
    default:
      return 'outlined';
  }
};

type DocumentButtonsProps = {
  recordStatusId: CashbackStatusId;
  isMainContractSigned: boolean;
  specificationId: number;
};

export const DocumentButtons = (props: DocumentButtonsProps) => {
  const { recordStatusId, isMainContractSigned, specificationId } = props;
  const isContractConfirmed = recordStatusId !== CASHBACK_STATUS_NOT_CONFIRMED;

  return (
    <Fragment>
      {!isContractConfirmed && <AcceptContractButton />}
      {isContractConfirmed && isMainContractSigned && (
        <DocumentToolbarButton
          component={RouterLink}
          variant={getDocumentButtonVariant(recordStatusId)}
          to={DorimPromoPaths.generateSpecificationDetailContractDocumentPath({
            specificationId: String(specificationId),
          })}
        >
          {getDocumentButtonText(recordStatusId)}
        </DocumentToolbarButton>
      )}
    </Fragment>
  );
};
