import { useTranslation } from 'react-i18next';
import { FormControl, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { RoundedButton } from '@/components/buttons/RoundedButton';
import { CustomFormLabel, RHFTextField } from '@/components/form-controls';
import type { SignUpPharmacy } from '@/pages/auth/sign-up/store/signUp.store';

// ----------------------------------------------------------------------

export type PharmacyFormFields = {
  name: string;
};

export const getDefaultPharmacyFormValues = (
  shouldPrefillForm: boolean,
  storedPharmacyData: Partial<SignUpPharmacy>,
) => ({
  name: shouldPrefillForm ? storedPharmacyData.name : '',
});

// ----------------------------------------------------------------------

type PharmacyInfoFormProps = {
  onFillForm: () => void;
  isFillButtonDisabled: boolean;
};

export const PharmacyInfoForm = ({ onFillForm, isFillButtonDisabled }: PharmacyInfoFormProps) => {
  const { t } = useTranslation('auth-sign-up');

  return (
    <Grid container rowSpacing={3} columnSpacing={2}>
      <Grid xs={12}>
        {/* NAME */}
        <FormControl fullWidth>
          <CustomFormLabel isRequired htmlFor="name">
            {t('forms.pharmacy-form.labels.name')}
          </CustomFormLabel>
          <RHFTextField fullWidth name="name" id="name" variant="filled" />
        </FormControl>
        {/* NAME END */}
      </Grid>

      <Grid xs={12}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" marginY="13px">
          <Typography component="p" sx={{ width: '168px', height: '32px', fontSize: '12px', color: 'neutral.700' }}>
            {t('forms.pharmacy-form.labels.fill-form')}
          </Typography>
          <RoundedButton variant="outlined" onClick={onFillForm} disabled={isFillButtonDisabled}>
            {t('forms.pharmacy-form.actions.fill')}
          </RoundedButton>
        </Stack>
      </Grid>
    </Grid>
  );
};
