import type { ExtractPathParams } from '@/shared/lib/react-router';
import { generatePath, joinPath } from '@/shared/lib/react-router';

const ROOT_PATH = '/requirements-calculation/';
const REQUIREMENTS_CALCULATION_DETAIL_PATH = joinPath(ROOT_PATH, ':dcId');
export type RequirementsCalculationDetailPathParams = ExtractPathParams<typeof REQUIREMENTS_CALCULATION_DETAIL_PATH>;

export type RequirementsCalculationPathState = {
  from: string;
};

export const RequirementsCalculationPaths = {
  ROOT_PATH,
  generateRequirementPath: (params: RequirementsCalculationDetailPathParams) =>
    generatePath(REQUIREMENTS_CALCULATION_DETAIL_PATH, params),
  REQUIREMENTS_CALCULATION_DETAIL_PATH,
};
