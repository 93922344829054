import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import type { AccountingSystem } from './modals/ImportFileModal/AccountingSystems';

type AccountingSystemStore = {
  state: {
    selectedAccountingSystem: AccountingSystem | null;
  };
  actions: {
    setSelectedAccountingSystem: (accountingSystem: AccountingSystem) => void;
    resetSelectedAccountingSystem: () => void;
  };
};

const useAccountingSystemStore = create<AccountingSystemStore>()(
  devtools(
    persist(
      set => ({
        state: {
          selectedAccountingSystem: null,
        },
        actions: {
          setSelectedAccountingSystem: selectedAccountingSystem =>
            set(
              {
                state: {
                  selectedAccountingSystem,
                },
              },
              false,
              'setSelectedAccountingSystem',
            ),
          resetSelectedAccountingSystem: () =>
            set({ state: { selectedAccountingSystem: null } }, false, 'resetSelectedAccountingSystem'),
        },
      }),
      {
        name: 'accounting-system-store',
        partialize: ({ state }) => ({
          state,
        }),
      },
    ),
    {
      name: 'accounting-system-store',
      enabled: import.meta.env.DEV,
    },
  ),
);

export const useAccountingSystemStoreActions = () => useAccountingSystemStore(({ actions }) => actions);
export const useSelectedAccountingSystem = () =>
  useAccountingSystemStore(({ state }) => state.selectedAccountingSystem);
