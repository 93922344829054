import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { MarketingOrgResponse } from '@/api/domains/dorim-promo.api';
import { formatDrugName } from '@/shared/helpers/format.helpers';
import { useMarketingOrg } from '@/shared/hooks/useMarketingOrg';
import {
  BreadcrumbLink,
  DorimPromoPaths,
  LazyBreadcrumb,
  type AnalyticsPathParams,
  type RouteObjectWithBreadcrumbs,
} from '@/shared/lib/react-router';

export const AnalyticsPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.ANALYTICS_PATH,
  handle: {
    breadcrumb: (params: AnalyticsPathParams) => ({
      Component: () => {
        const { t } = useTranslation('specifications-detail-page');
        const { data: analyticsDrug, isLoading } = useMarketingOrg({
          specificationId: Number(params.specificationId),
          marketingOrgId: Number(params.marketingOrgId),
          select: useCallback((data: MarketingOrgResponse | undefined) => {
            const detail = data?.marketing_org_details?.cashback_drugs?.find(
              drug => drug.drug_id === Number(params.drugId),
            );

            return detail?.drug;
          }, []),
        });

        return (
          <LazyBreadcrumb isLoading={isLoading}>
            <BreadcrumbLink to={DorimPromoPaths.generateAnalyticsPath(params)}>
              {analyticsDrug
                ? formatDrugName(analyticsDrug.name, analyticsDrug.form, analyticsDrug.number)
                : t('routes.analytics-route')}
            </BreadcrumbLink>
          </LazyBreadcrumb>
        );
      },
    }),
  },
  lazy: async () => {
    const { AnalyticsPage } = await import(
      /* webpackChunkName: "AnalyticsPage" */
      './AnalyticsPage'
    );

    return {
      Component: AnalyticsPage,
    };
  },
};
