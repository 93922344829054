import { useTranslation } from 'react-i18next';

import { Label } from '@/components/labels';
import {
  CASHBACK_STATUS_UNKNOWN,
  CashbackColorsByStatusId,
  type CashbackStatusId,
} from '@/shared/constants/cashback-status.constants';

type CashbackStatusProps = {
  statusId: CashbackStatusId;
};

export const CashbackStatus = (props: CashbackStatusProps) => {
  const { statusId } = props;
  const { t } = useTranslation('status-constants');
  const statusLabel = t(`cashback-status-constants.${statusId}`);

  const color = CashbackColorsByStatusId[statusId] || 'default';

  return <Label color={color}>{statusLabel || CASHBACK_STATUS_UNKNOWN}</Label>;
};
