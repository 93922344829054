import i18next from 'i18next';

import { Yup } from '@/shared/lib/yup';

export let FormSchema: Yup.ObjectSchema<
  {
    code: string;
  },
  Yup.AnyObject,
  {
    code: undefined;
  },
  ''
>;

i18next.on('languageChanged', () => {
  FormSchema = Yup.object().shape({
    code: Yup.string()
      .required(i18next.t('components.verify-phone.form.validation-schema.required', { ns: 'auth-page' }))
      .min(4, ({ min }) =>
        i18next.t('components.verify-phone.form.validation-schema.code.min', { ns: 'auth-page', values: { min } }),
      ),
  });
});
