import { useTranslation } from 'react-i18next';

import { ConfirmationDialogContainer, ConfirmationDialogHeader } from '@/components/dialogs';

import { useSpecificationContextWindowsVisibility, useSpecificationStoreActions } from '../../../../../lib';
import { CalculationErrorReportForm } from './ui';

export const CalculationErrorReportDialog = () => {
  const { isCalculationErrorReportDialogVisible } = useSpecificationContextWindowsVisibility();
  const { closeCalculationErrorReportDialog } = useSpecificationStoreActions();
  const { t } = useTranslation('specifications-detail-page');

  return (
    <ConfirmationDialogContainer
      open={isCalculationErrorReportDialogVisible}
      fullWidth
      maxWidth="xs"
      subject="warning"
      onClose={closeCalculationErrorReportDialog}
    >
      <ConfirmationDialogHeader>{t('dialogs.calculation-error-report-dialog.title')}</ConfirmationDialogHeader>
      <CalculationErrorReportForm />
    </ConfirmationDialogContainer>
  );
};
