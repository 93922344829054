import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import type { StackProps } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import type { Merge } from 'type-fest';

import type { ValidationRuleStatus } from '@/modules/account/PasswordStrengthValidation/types';
import { ValidationRule } from '@/modules/account/PasswordStrengthValidation/ValidationRule';
import { ValidationStatusBadge } from '@/modules/account/PasswordStrengthValidation/ValidationStatusBadge';

import { rulesConfig } from './config';

export type PasswordStrengthValidationProps = Merge<
  StackProps,
  {
    validationResult: ValidationRuleStatus[];
  }
>;

export const PasswordStrengthValidation = memo((props: PasswordStrengthValidationProps) => {
  const { validationResult, ...restOfProps } = props;
  const { t } = useTranslation('account');
  return (
    <Stack spacing={2} {...restOfProps}>
      <Typography variant="Body/Bold/large">{t('password-strength-validation.title')}</Typography>

      <Stack component="ul" spacing={1}>
        {rulesConfig.map((rule, index) => (
          <ValidationRule key={rule.id} order={index + 1} label={rule.label} status={validationResult[index]} />
        ))}
      </Stack>

      <ValidationStatusBadge validationResult={validationResult} />
    </Stack>
  );
});
