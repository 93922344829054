import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { DorimPromoApi } from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';
import { useGlobalErrorStore } from '@/components/dialogs';

export const useAcceptSpecificationContract = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { setGlobalError } = useGlobalErrorStore();
  const { t } = useTranslation('specifications-detail-page');

  return useMutation({
    mutationFn: DorimPromoApi.acceptSpecificationContract,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: DorimPromoQueryKeys.root });
      snackbar.enqueueSnackbar(t('dialogs.specification-contract-acceptance-dialog.snackbars.specification-accepted'), {
        variant: 'success',
      });
    },
    onError: setGlobalError,
  });
};
