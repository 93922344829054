import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useTimer } from 'react-timer-hook';

import { TemporarilyBlocked } from '@/pages/auth/components/Ban/TemporarilyBlocked';
import { StyledFormRoot } from '@/pages/auth/components/styles';
import { useBan } from '@/pages/auth/reset-password/hooks/useBan';
import { AuthPaths } from '@/shared/lib/react-router';

export const BanPage = () => {
  const navigate = useNavigate();
  const { expiredAt } = useBan();
  const { t } = useTranslation('auth-reset-password');
  const expiryDate = new Date(expiredAt);

  useTimer({
    expiryTimestamp: expiryDate,
    onExpire: () => {
      navigate(AuthPaths.RESET_PASSWORD.ROOT);
    },
  });

  return (
    <StyledFormRoot>
      <TemporarilyBlocked title={t('ban-page.title')} expiryDate={expiryDate} />
    </StyledFormRoot>
  );
};
