import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { DistributorInfoModal } from '@/components/DistributorInfoModal';

import { BaseLayout } from '../BaseLayout';
import { Header } from '../Header';
import { PageContainer } from '../Page';
import { ProductSelectionLayoutTabs } from './ProductSelectionLayoutTabs';

export const ProductSelectionLayout = () => {
  const { t } = useTranslation('product-selection-page');

  return (
    <Fragment>
      <BaseLayout>
        <PageContainer title={t('layout.title')}>
          <Header title={t('layout.title')} />
          <ProductSelectionLayoutTabs />
          <Outlet />
        </PageContainer>
      </BaseLayout>

      <DistributorInfoModal />
    </Fragment>
  );
};
