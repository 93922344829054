import { useTranslation } from 'react-i18next';

import type { ConfirmationDialogContainerProps } from '@/components/dialogs/elements.confirmation-dialog';
import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
} from '@/components/dialogs/elements.confirmation-dialog';

// ----------------------------------------------------------------------

export type ConfirmationCreateDialogProps = Omit<ConfirmationDialogContainerProps, 'subject'> & {
  isLoading: boolean;
  onProceed: () => void;
};

// ----------------------------------------------------------------------

export const ConfirmationCreateDialog = (props: ConfirmationCreateDialogProps) => {
  const { isLoading, onClose, onProceed, ...restOfDialogProps } = props;
  const { t } = useTranslation('confirmation-dialog-elements');

  return (
    <ConfirmationDialogContainer subject="create" onClose={onClose} {...restOfDialogProps}>
      <ConfirmationDialogHeader>{t('confirmation-create-dialog.header')}</ConfirmationDialogHeader>

      <ConfirmationDialogBodyMutedText>{t('confirmation-create-dialog.muted-text')}</ConfirmationDialogBodyMutedText>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={onClose} />
        <ConfirmationDialogActionProceedButton autoFocus loading={isLoading} onClick={onProceed}>
          {t('confirmation-create-dialog.actions.create')}
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
