import { useTranslation } from 'react-i18next';

import { Modal, ModalBody, ModalHeader } from '../Modal';
import {
  useDistributorInfoActions,
  useIsDistributorInfoModalOpen,
  useResetDistributorInfoStoreOnUnmount,
} from './distributor-info-modal.store';
import { DistributorInfoContent } from './DistributorInfoContent';

export const DistributorInfoModal = () => {
  const isOpen = useIsDistributorInfoModalOpen();
  const { closeDistributorInfoModal: closeDistributorContactsModal, resetSelectedDistributor } =
    useDistributorInfoActions();

  useResetDistributorInfoStoreOnUnmount();
  const { t } = useTranslation('distributor-info-modal');

  return (
    <Modal
      open={isOpen}
      maxWidth="lg"
      onClose={closeDistributorContactsModal}
      onTransitionExited={resetSelectedDistributor}
    >
      <ModalHeader onClose={closeDistributorContactsModal} title={t('distributor-info-content.title')} />

      <ModalBody sx={{ padding: '24px !important' }}>
        <DistributorInfoContent />
      </ModalBody>
    </Modal>
  );
};
