import { useTranslation } from 'react-i18next';
import { Box, Stack, styled, Typography } from '@mui/material';
import { some } from 'lodash';
import type { Merge } from 'type-fest';

import {
  CASHBACK_STATUS_ARCHIVE,
  CASHBACK_STATUS_CALCULATED,
  CASHBACK_STATUS_CLARIFICATION,
  CASHBACK_STATUS_PAYMENT_COMPLETED,
  type CashbackStatusId,
} from '@/shared/constants/cashback-status.constants';

import { DescriptionBase, type DescriptionBaseProps } from './DescriptionBase';
import { Forecast } from './Forecast';
import { TotalAmount } from './TotalAmount';

type DescriptionProps = Merge<
  Pick<DescriptionBaseProps, 'hasDivider' | 'description'>,
  {
    paymentTitle: string;
    paymentValue: number;
    minBonus?: number;
    maxBonus?: number;
    recordStatusId?: CashbackStatusId;
  }
>;

const Card = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5, 2),
}));

const ForecastCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.secondary['+2'],
}));

const PaymentCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.tetriary['+2'],
}));

const DescriptionContent = (props: Omit<DescriptionProps, 'hasDivider' | 'description'>) => {
  const { t } = useTranslation('specifications-detail-page');
  const { paymentTitle, paymentValue, minBonus, maxBonus, recordStatusId } = props;

  const isShowingForecastAllowed =
    !!minBonus &&
    !!maxBonus &&
    !some(
      [
        CASHBACK_STATUS_CALCULATED,
        CASHBACK_STATUS_CLARIFICATION,
        CASHBACK_STATUS_PAYMENT_COMPLETED,
        CASHBACK_STATUS_ARCHIVE,
      ],
      statusId => statusId === recordStatusId,
    );

  return (
    <Stack direction="row" spacing={2}>
      {isShowingForecastAllowed && (
        <ForecastCard alignSelf="flex-end">
          <Typography variant="Body/small" color="neutral.700">
            {t('description.forecast.title')}
          </Typography>
          <Forecast from={minBonus} to={maxBonus} />
        </ForecastCard>
      )}
      <PaymentCard alignSelf="flex-end">
        <Typography variant="Body/small" color="neutral.700">
          {paymentTitle}
        </Typography>
        <TotalAmount paymentSum={paymentValue} />
      </PaymentCard>
    </Stack>
  );
};

export const Description = (props: DescriptionProps) => {
  const { hasDivider, description, ...contentProps } = props;

  return (
    <DescriptionBase hasDivider={hasDivider} description={description}>
      <DescriptionContent {...contentProps} />
    </DescriptionBase>
  );
};
