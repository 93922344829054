import { Trans } from 'react-i18next';
import { Link } from '@mui/material';

import { SUPPORT_PHONE_CALLTO, SUPPORT_PHONE_FORMATTED } from '@/shared/constants/dorim.constants';

import { StyledPhoneIcon, StyledSupport } from './styles';

export const SupportLink = () => {
  return (
    <StyledSupport>
      <Trans ns="auth-page" i18nKey="components.support-link.support" components={{ br: <br /> }} />
      <Link href={`tel:${SUPPORT_PHONE_CALLTO}`} underline="hover" color="inherit">
        <StyledPhoneIcon />
        {SUPPORT_PHONE_FORMATTED}
      </Link>
    </StyledSupport>
  );
};
