import i18next from 'i18next';

import type { LegalStatus } from '@/api/domains/contractors.types';

// В форме регистрации используется физический адрес для компании и аптеки (согласно документации)
export let PHYSIC_ADDRESS: {
  id: number;
  author_id: number;
  initiator_id: number;
  created_at: string;
  updated_at: string;
  author: null;
  initiator: null;
  parent_id: number;
  alias: string;
  name: string;
  description: string;
};

export let companyLegalStatuses: LegalStatus[];

i18next.on('languageChanged', () => {
  PHYSIC_ADDRESS = {
    id: 29,
    author_id: 0,
    initiator_id: 0,
    created_at: '2023-06-25T12:35:32.363092+00:00',
    updated_at: '2023-06-25T12:35:32.363092+00:00',
    author: null,
    initiator: null,
    parent_id: 0,
    alias: 'address_type',
    name: i18next.t('registration-constants.physic-address-name', {
      ns: 'auth-sign-up',
    }),
    description: '',
  };

  companyLegalStatuses = [
    {
      id: 18,
      author_id: 0,
      initiator_id: 0,
      created_at: '2023-06-25T12:35:32.363092+00:00',
      updated_at: '2023-06-25T12:35:32.363092+00:00',
      author: null,
      initiator: null,
      parent_id: 0,
      alias: 'contractor_legal_status',
      name: i18next.t('registration-constants.company-legal-statuses-names.limited-liability-company', {
        ns: 'auth-sign-up',
      }),
      description: '',
    },
    {
      id: 19,
      author_id: 0,
      initiator_id: 0,
      created_at: '2023-06-25T12:35:32.363092+00:00',
      updated_at: '2023-06-25T12:35:32.363092+00:00',
      author: null,
      initiator: null,
      parent_id: 0,
      alias: 'contractor_legal_status',
      name: i18next.t('registration-constants.company-legal-statuses-names.private-company', {
        ns: 'auth-sign-up',
      }),
      description: '',
    },
    {
      id: 20,
      author_id: 0,
      initiator_id: 0,
      created_at: '2023-06-25T12:35:32.363092+00:00',
      updated_at: '2023-06-25T12:35:32.363092+00:00',
      author: null,
      initiator: null,
      parent_id: 0,
      alias: 'contractor_legal_status',
      name: i18next.t('registration-constants.company-legal-statuses-names.state-owened-company', {
        ns: 'auth-sign-up',
      }),
      description: '',
    },
  ];
});
