import i18next from 'i18next';

export type WarningType = 'address' | 'signupToken' | 'internalError' | 'default';
type WarningMessage = {
  headerText: string;
  bodyText: string;
};

export let warningMessage: Record<WarningType, WarningMessage>;
export let INTERNAL_ERROR_MESSAGE: string;

i18next.on('languageChanged', () => {
  warningMessage = {
    address: {
      headerText: i18next.t('forms.pharmacy-form.warning-message.address.headerText', { ns: 'auth-sign-up' }),
      bodyText: i18next.t('forms.pharmacy-form.warning-message.address.bodyText', { ns: 'auth-sign-up' }),
    },
    signupToken: {
      headerText: i18next.t('forms.pharmacy-form.warning-message.signup-token.headerText', { ns: 'auth-sign-up' }),
      bodyText: i18next.t('forms.pharmacy-form.warning-message.signup-token.bodyText', { ns: 'auth-sign-up' }),
    },
    internalError: {
      headerText: i18next.t('forms.pharmacy-form.warning-message.internal-error.headerText', { ns: 'auth-sign-up' }),
      bodyText: i18next.t('forms.pharmacy-form.warning-message.internal-error.bodyText', { ns: 'auth-sign-up' }),
    },
    default: {
      headerText: i18next.t('forms.pharmacy-form.warning-message.default.headerText', { ns: 'auth-sign-up' }),
      bodyText: i18next.t('forms.pharmacy-form.warning-message.default.bodyText', { ns: 'auth-sign-up' }),
    },
  };

  INTERNAL_ERROR_MESSAGE = warningMessage.internalError.headerText + '\n' + warningMessage.internalError.bodyText;
});
