import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { find } from 'lodash';

import type { FetchRequirementParams } from '@/api/domains/requirements-calculation.api.ts';
import { RequirementsCalculationApi } from '@/api/domains/requirements-calculation.api.ts';
import { RequirementsCalculationQueryKeys } from '@/api/domains/requirements-calculation.query-keys.ts';
import type { Requirement } from '@/api/domains/requirements-calculation.types';

export type UseRequirementSelector<TData> = (data: Requirement) => TData;

type UseRequirementParams<TData> = FetchRequirementParams & {
  select?: UseRequirementSelector<TData>;
};

export const useRequirement = <TData = Requirement>(params: UseRequirementParams<TData>) => {
  return useQuery({
    queryKey: RequirementsCalculationQueryKeys.getRequirementKey({ dc_id: params.dc_id }),
    queryFn: async () => {
      return await RequirementsCalculationApi.fetchRequirement({ dc_id: params.dc_id });
    },
    staleTime: Infinity,
    enabled: Boolean(params.dc_id),
    select: params.select,
  });
};

type UseRequirementRowToOrderCountParams = FetchRequirementParams & {
  rowId: number;
};

export const useRequirementRowToOrderCount = (params: UseRequirementRowToOrderCountParams) => {
  const { data: count = 0 } = useRequirement({
    dc_id: params.dc_id,
    select: useCallback(
      (requirement: Requirement) => {
        const foundedRow = find(requirement.rows, row => row.id === params.rowId);

        if (!foundedRow) return 0;
        return foundedRow.to_order;
      },
      [params.rowId],
    ),
  });

  return count;
};

export const useRequirementPositionSummary = (params: FetchRequirementParams) => {
  const { data: count = 0 } = useRequirement({
    dc_id: params.dc_id,
    select: useCallback((requirement: Requirement) => {
      return requirement.rows.length;
    }, []),
  });

  return count;
};
