import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { HeaderTabs } from '@/layouts/Header';
import { useSpecificationContractDocument } from '@/shared/hooks/useSpecificationContractDocument';
import { DorimPromoPaths, useMatchLinks } from '@/shared/lib/react-router';

import { useSpecificationIdFromPathParams } from '../../../lib';

export const LayoutTabs = () => {
  const specificationId = useSpecificationIdFromPathParams();
  const stringifiedSpecificationId = String(specificationId);

  const { query } = useSpecificationContractDocument({ specificationId });
  const { t } = useTranslation('specifications-contract');
  const specificationContractDocument = query.data;

  const documentPath = DorimPromoPaths.generateSpecificationDetailContractDocumentPath({
    specificationId: stringifiedSpecificationId,
  });
  const signatureProtocolPath = DorimPromoPaths.generateSpecificationDetailContractSignatureProtocolPath({
    specificationId: stringifiedSpecificationId,
  });

  const [matchedLink] = useMatchLinks(documentPath, signatureProtocolPath);

  return (
    <HeaderTabs>
      {matchedLink?.pathname && (
        <Tabs value={matchedLink.pathname} textColor="primary" indicatorColor="primary">
          <Tab
            component={Link}
            to={documentPath}
            value={documentPath}
            label={t('detail.layout.layout-tabs.specification')}
          />
          <Tab
            disabled={!specificationContractDocument?.is_signed || !specificationContractDocument?.protocol_src}
            component={Link}
            to={signatureProtocolPath}
            value={signatureProtocolPath}
            label={t('detail.layout.layout-tabs.signature-protocol')}
          />
        </Tabs>
      )}
    </HeaderTabs>
  );
};
