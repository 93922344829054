import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';
import { ReportApi } from '@/api/domains/report.api';

export const useSendDorimPromoCalculationErrorReport = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const { t } = useTranslation('specifications-detail-page');

  return useMutation({
    mutationFn: ReportApi.sendDorimPromoCalculationErrorReport,
    onSuccess: async () => {
      snackbar.enqueueSnackbar(t('dialogs.calculation-error-report-dialog.snackbars.report-success'), {
        variant: 'success',
      });
      await queryClient.invalidateQueries({ queryKey: DorimPromoQueryKeys.root });
    },
  });
};
