import i18next from 'i18next';

import { Yup } from '@/shared/lib/yup';

export let accountSchema: Yup.ObjectSchema<
  {
    phoneNumber: string | undefined;
  },
  Yup.AnyObject,
  {
    phoneNumber: undefined;
  },
  ''
>;

i18next.on('languageChanged', () => {
  accountSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(i18next.t('account-page.form.account-schema.required', { ns: 'auth-reset-password' }))
      .isPossiblePhoneNumber(),
  });
});
