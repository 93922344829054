import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import type { CheckboxProps } from '@mui/material';
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

import { styles } from './styles';

type Props = {
  isChecked: boolean;
  isLoaded: boolean;
  isNotChecked: boolean;
  onClick: () => void;
  onCheckboxChange: (value: boolean) => void;
};

export const FooterForm = ({ isChecked, isLoaded, isNotChecked, onCheckboxChange, onClick }: Props) => {
  const { t } = useTranslation('privacy-policy');

  const handleCheckboxChange: CheckboxProps['onChange'] = event => {
    onCheckboxChange(event.target.checked);
  };

  return (
    <Box sx={styles.footerForm}>
      <FormControl>
        <FormControlLabel
          control={<Checkbox checked={isChecked} color="secondary" onChange={handleCheckboxChange} />}
          label={t('modal.footer-form.checkbox-label')}
        />
        {isNotChecked && (
          <FormHelperText error sx={{ mt: -1, ml: '29px' }}>
            {t('modal.footer-form.form-helper-text')}
          </FormHelperText>
        )}
      </FormControl>
      <Box>
        <LoadingButton color="secondary" loading={isLoaded} onClick={onClick} variant="contained">
          {t('modal.actions.submit')}
        </LoadingButton>
      </Box>
    </Box>
  );
};
