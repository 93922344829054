import { useTranslation } from 'react-i18next';
import { Divider, Stack, Typography } from '@mui/material';

import { type CloudCartSummery } from '@/entities/cloud-carts';

import { AnimatedSummaryEntry } from './SummaryBarEntry';

export type SummaryBarContentProps = {
  isLoading: boolean;
  summery: CloudCartSummery;
};

export const SummaryBarContent = (props: SummaryBarContentProps) => {
  const { isLoading, summery } = props;
  const { t } = useTranslation('cloud-cart');

  return (
    <Stack direction="row" alignItems="center" spacing={4} pr={4}>
      <Typography noWrap variant="Body/Bold/large" data-testid="summary-bar-info-label">
        {t('summary-bar.title')}
      </Typography>
      <Divider flexItem orientation="vertical" />
      <Stack direction="row" spacing={4} alignItems="center" color={isLoading ? 'neutral.500' : 'text.primary'}>
        <AnimatedSummaryEntry
          name={t('summary-bar.labels.entries')}
          value={summery.entriesCount}
          minWidth="64px"
          data-testid="summary-bar-entries-count-info"
        />
        <Divider flexItem orientation="vertical" />
        <AnimatedSummaryEntry
          name={t('summary-bar.labels.prepayment-with-vat')}
          value={summery.totalPrepaymentWithVat}
          data-testid="summary-bar-total-prepayment-with-vat-info"
        />
        <AnimatedSummaryEntry
          name={t('summary-bar.labels.installment-payment-with-vat')}
          value={summery.totalInstallmentPaymentWithVat}
        />
        <AnimatedSummaryEntry
          name={t('summary-bar.labels.discount-with-vat')}
          value={summery.totalDiscountWithVat}
          color={isLoading ? 'neutral.500' : 'custom.green'}
        />
        <AnimatedSummaryEntry
          name={t('summary-bar.labels.price-with-vat')}
          value={summery.totalPriceWithVat}
          color={isLoading ? 'neutral.500' : 'primary.main'}
          data-testid="summary-bar-total-price-with-vat"
        />
      </Stack>
      <Divider flexItem orientation="vertical" />
    </Stack>
  );
};
