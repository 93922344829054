import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export type PageContainerBaseProps = React.PropsWithChildren<{
  title: string;
  meta?: React.ReactNode;
}>;

export const PageContainerBase = (props: PageContainerBaseProps) => {
  const { children, title, meta } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>
          {title} | {t('Dorim Price')}
        </title>
        {meta}
      </Helmet>
      {children}
    </Fragment>
  );
};
