import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  Collapse,
  collapseClasses,
  Paper,
  Portal,
  Stack,
  Typography,
  type CollapseProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

type StyledCollapseProps = Pick<SettingsFormActionbarProps, 'isFloating'>;

const StyledCollapse = styled(Collapse, {
  shouldForwardProp: prop => prop !== 'isFloating',
})<StyledCollapseProps>(
  {
    [`.${collapseClasses.wrapperInner}`]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  ({ theme, isFloating }) =>
    isFloating && {
      position: 'fixed',
      bottom: theme.spacing(3),
      zIndex: theme.zIndex.appBar,
      width: '100%',
    },
) as React.FC<CollapseProps & StyledCollapseProps>;

const StyledRoot = styled(Card)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 560,
  padding: theme.spacing(1, 1, 1, 3),
  backgroundColor: theme.palette.secondary.main,
})) as typeof Card;

export type SettingsFormActionbarProps = {
  isOpen: boolean;
  isSubmitting: boolean;
  className?: string;
  children: string;
  onReset: () => void;
} & (
  | {
      isFloating: true;
      formId: string;
    }
  | {
      isFloating: false;
      formId?: string;
    }
);

export const FormActionbar = (props: SettingsFormActionbarProps) => {
  const { isOpen, isFloating, isSubmitting = false, className, formId, children, onReset } = props;
  const { t } = useTranslation('settings-page');

  return (
    <Portal disablePortal={!isFloating}>
      <StyledCollapse in={isOpen} isFloating={isFloating} mountOnEnter unmountOnExit className={className}>
        <StyledRoot component={Stack} direction="row" spacing={2}>
          <Typography variant="Body/medium" color="common.white">
            {children}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Button variant="contained" color="secondary" onClick={onReset}>
              {t('distributors.action-bar.actions.reset')}
            </Button>
            <Paper>
              <LoadingButton loading={isSubmitting} type="submit" variant="soft" color="inherit" form={formId}>
                {t('distributors.action-bar.actions.submit')}
              </LoadingButton>
            </Paper>
          </Stack>
        </StyledRoot>
      </StyledCollapse>
    </Portal>
  );
};
