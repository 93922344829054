import type { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, Stack } from '@mui/material';
import i18next from 'i18next';

import type { SignInFormData } from '@/api/domains/auth.api';
import { useGlobalErrorStore } from '@/components/dialogs';
import {
  CustomFormLabel,
  RHFCheckbox,
  RHFMaskedPhoneTextField,
  RHFPasswordTextField,
} from '@/components/form-controls';
import { StyledFormProvider, StyledFormRoot, StyledFormTitle } from '@/pages/auth/components/styles';
import { LANDING_PAGE_LINK } from '@/shared/constants/dorim.constants';
import { useAuthMethods, useSessionTokensActions, useSessionTokensShouldStaySignedIn } from '@/shared/lib/auth';
import { AuthPaths } from '@/shared/lib/react-router';
import { Yup } from '@/shared/lib/yup';

import { useSignInStoreActions } from './signIn.store';
import { StyledFormContainer, StyledLandingLink, StyledRestorePasswordLink } from './styles';

// ----------------------------------------------------------------------

type FormValues = SignInFormData & {
  shouldStaySignedIn: boolean;
};

// ----------------------------------------------------------------------

let signInSchema: Yup.ObjectSchema<
  {
    phone: string;
    password: string;
    shouldStaySignedIn: boolean | undefined;
  },
  Yup.AnyObject,
  {
    phone: undefined;
    password: undefined;
    shouldStaySignedIn: undefined;
  },
  ''
>;

i18next.on('languageChanged', () => {
  signInSchema = Yup.object().shape({
    phone: Yup.string()
      .isPossiblePhoneNumber()
      .required(i18next.t('form.form-schema.required', { ns: 'auth-sign-in' })),
    password: Yup.string()
      .trim()
      .required(i18next.t('form.form-schema.required', { ns: 'auth-sign-in' })),
    shouldStaySignedIn: Yup.boolean(),
  });
});

// ----------------------------------------------------------------------

export const SignInForm = () => {
  const { setPhone } = useSignInStoreActions();
  const { setShouldStaySignedIn } = useSessionTokensActions();

  const storedStaySignedIn = useSessionTokensShouldStaySignedIn();
  const { t } = useTranslation('auth-sign-in');

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(signInSchema),
    defaultValues: {
      phone: '',
      password: '',
      shouldStaySignedIn: storedStaySignedIn ?? true,
    },
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const { signIn } = useAuthMethods();
  const { setGlobalError } = useGlobalErrorStore();

  const handleSubmit = async (formData: FormValues) => {
    try {
      const { password, phone } = formData;

      setPhone(phone);

      await signIn({ password, phone });
    } catch (error) {
      setGlobalError(error);
    }
  };

  const handleStaySignedInChanged = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShouldStaySignedIn(checked);
  };

  return (
    <StyledFormRoot>
      <StyledFormContainer>
        <StyledFormTitle>{t('form.title')}</StyledFormTitle>

        <StyledFormProvider methods={methods} onSubmit={handleSubmit}>
          <Stack spacing={3} mb={3}>
            <FormControl fullWidth>
              <CustomFormLabel htmlFor="phone">{t('form.labels.phone')}</CustomFormLabel>
              <RHFMaskedPhoneTextField
                fullWidth
                name="phone"
                id="phone"
                autoComplete="phone"
                variant="filled"
                inputProps={{ 'data-testid': 'signin-phone-input' }}
              />
            </FormControl>

            <FormControl fullWidth>
              <CustomFormLabel htmlFor="password">{t('form.labels.password')}</CustomFormLabel>
              <RHFPasswordTextField
                fullWidth
                name="password"
                id="password"
                autoComplete="password"
                variant="filled"
                inputProps={{ 'data-testid': 'signin-password-input' }}
              />
            </FormControl>
          </Stack>

          <FormControl>
            <RHFCheckbox
              name="shouldStaySignedIn"
              label={t('form.labels.should-stay-signed-in')}
              sx={{ marginBottom: 5 }}
              CheckboxProps={{
                color: 'secondary',
                onChange: handleStaySignedInChanged,
                checked: storedStaySignedIn,
              }}
            />
          </FormControl>

          <Stack spacing={2} justifyContent="center" alignItems="center">
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="secondary"
              data-testid="signin-btn"
            >
              {t('form.actions.sign-in')}
            </LoadingButton>

            <StyledRestorePasswordLink to={AuthPaths.RESET_PASSWORD.ROOT}>
              <Button fullWidth variant="text" color="secondary" data-testid="signin-reset-password-btn">
                {t('form.actions.reset-password')}
              </Button>
            </StyledRestorePasswordLink>
          </Stack>
        </StyledFormProvider>
      </StyledFormContainer>

      <StyledLandingLink to={LANDING_PAGE_LINK}>
        <Button variant="outlined" color="secondary" data-testid="signin-to-main-page-btn">
          {t('form.actions.go-to-main-page')}
        </Button>
      </StyledLandingLink>
    </StyledFormRoot>
  );
};
