import { Trans, useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { PanelBase } from '@/layouts/PanelBase';
import { ReactComponent as AlertSVG } from '@/shared/assets/icons/minimal/ic_alert.svg';
import { formatWordEnding } from '@/shared/helpers/format.helpers';

import type { DataGridPaginationProps } from './types';
import { useDataGridPagination } from './useDataGridPagination';

// ----------------------------------------------------------------------

const StyledRoot = styled(Stack)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  minHeight: '100%',
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.warning['+2'],
})) as typeof Stack;

// ----------------------------------------------------------------------

export const DataGridPagination = (props: DataGridPaginationProps) => {
  const { isLoading, hasNextPage, dataSource } = props;
  const dataSourceLength = dataSource.length;

  const { t } = useTranslation('components');
  const { handleShowMore } = useDataGridPagination(props);

  if (!hasNextPage) {
    return null;
  }

  return (
    <PanelBase>
      <StyledRoot direction="row" spacing={1.5}>
        <SvgIcon component={AlertSVG} inheritViewBox color="warning" />
        <Typography component="p" variant="Body/medium" color="warning.darker" sx={{ flexGrow: 1 }}>
          <Trans
            ns="components"
            i18nKey="data-grid-pagination.description"
            values={{
              records: ` ${dataSourceLength} ${formatWordEnding(dataSourceLength, [t('data-grid-pagination.record'), t('data-grid-pagination.records-few'), t('data-grid-pagination.records-many')])}`,
            }}
          />
        </Typography>

        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="warning"
          size="small"
          onClick={handleShowMore}
          sx={{ flexShrink: 0 }}
        >
          {t('data-grid-pagination.actions.show-more')}
        </LoadingButton>
      </StyledRoot>
    </PanelBase>
  );
};
