import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockedUser } from '@/pages/auth/components/Ban/BlockedUser';
import { StyledTitle } from '@/pages/auth/components/Ban/TemporarilyBlocked/styles';
import { formatDateTime } from '@/shared/helpers/format.helpers';

export type TemporarilyBlockedProps = {
  expiryDate: Date;
  title?: string;
};

export const TemporarilyBlocked = ({ expiryDate, title }: TemporarilyBlockedProps) => {
  const formattedDate = formatDateTime(expiryDate, 'dd.MM.yyyy HH:mm');
  const { t } = useTranslation('auth-page');

  return (
    <Fragment>
      {title && <StyledTitle>{title}</StyledTitle>}
      <BlockedUser
        reasonText={t('components.ban.temporarily-blocked.reason-text')}
        detailsText={`${t('components.ban.temporarily-blocked.till')} ${formattedDate}`}
      />
    </Fragment>
  );
};
