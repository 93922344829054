import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentToolbarButton } from '@/components/DocumentToolbar';

import { useSpecificationStoreActions } from '../../../../../../../lib';
import { SpecificationContractAcceptanceDialog } from './ui';

export const AcceptContractButton = () => {
  const { openAcceptContractConfirmationDialog } = useSpecificationStoreActions();
  const { t } = useTranslation('specifications-detail-page');

  return (
    <Fragment>
      <DocumentToolbarButton variant="contained" onClick={openAcceptContractConfirmationDialog}>
        {t('document-toolbar.actions.confirm-specification')}
      </DocumentToolbarButton>
      <SpecificationContractAcceptanceDialog />
    </Fragment>
  );
};
