import { useTranslation } from 'react-i18next';

import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
} from '@/components/dialogs';

import { useNavigateConfirmationDialog } from './useNavigateConfirmationDialog';

export const NavigateConfirmationDialog = () => {
  const { isOpen, message, handleCancel, handleConfirm } = useNavigateConfirmationDialog();
  const { t } = useTranslation('dialogs');

  return (
    <ConfirmationDialogContainer open={isOpen} subject="warning" transitionDuration={0} onClose={handleCancel}>
      <ConfirmationDialogHeader>{message}</ConfirmationDialogHeader>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={handleCancel} />
        <ConfirmationDialogActionProceedButton autoFocus onClick={handleConfirm}>
          {t('navigate-confirmation-dialog.actions.proceed')}
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
