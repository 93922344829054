import { useId } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import i18next from 'i18next';
import { useSnackbar } from 'notistack';
import type { InferType } from 'yup';

import type { UserContractor } from '@/api/domains/user-contractor.type';
import { useGlobalErrorStore, useNavigationPrompt } from '@/components/dialogs';
import {
  CustomFormLabel,
  FormProvider,
  RHFMaskedPhoneTextField,
  RHFNumberTextField,
  RHFTextField,
} from '@/components/form-controls';
import { FormActionbar } from '@/components/FormActionbar';
import { Yup } from '@/shared/lib/yup';

import { useUpdateUserContractor } from '../useUpdateUserContractor';

let formSchema: Yup.ObjectSchema<
  {
    bankName: string;
    bankMfo: string;
    bankCheckingAccount: string;
    directorName: string;
    directorPhone: string;
    addressRow: string;
  },
  Yup.AnyObject,
  {
    bankName: undefined;
    bankMfo: undefined;
    bankCheckingAccount: undefined;
    directorName: undefined;
    directorPhone: undefined;
    addressRow: undefined;
  },
  ''
>;

i18next.on('languageChanged', () => {
  formSchema = Yup.object().shape({
    bankName: Yup.string()
      .trim()
      .required(i18next.t('entity-info.entity-info-form.form-schema.required', { ns: 'profile-page' })),
    bankMfo: Yup.string()
      .trim()
      .required(i18next.t('entity-info.entity-info-form.form-schema.required', { ns: 'profile-page' }))
      .test(
        'length',
        i18next.t('entity-info.entity-info-form.form-schema.messages.bankMfo', { ns: 'profile-page' }),
        value => value.length === 5,
      ),
    bankCheckingAccount: Yup.string()
      .trim()
      .required(i18next.t('entity-info.entity-info-form.form-schema.required', { ns: 'profile-page' }))
      .test(
        'length',
        i18next.t('entity-info.entity-info-form.form-schema.messages.bankCheckingAccount', { ns: 'profile-page' }),
        value => value.length === 20,
      ),
    directorName: Yup.string()
      .trim()
      .required(i18next.t('entity-info.entity-info-form.form-schema.required', { ns: 'profile-page' })),
    directorPhone: Yup.string()
      .isPossiblePhoneNumber()
      .required(i18next.t('entity-info.entity-info-form.form-schema.required', { ns: 'profile-page' })),
    addressRow: Yup.string()
      .trim()
      .required(i18next.t('entity-info.entity-info-form.form-schema.required', { ns: 'profile-page' })),
  });
});

type FormSchema = InferType<typeof formSchema>;

export type EntityInfoFormProps = {
  userContractor: UserContractor;
};

export const EntityInfoForm = (props: EntityInfoFormProps) => {
  const { userContractor } = props;

  const formId = useId();
  const agreementsData = userContractor?.agreements_data;
  const directorContact = userContractor?.director_contact;

  const { t } = useTranslation('profile-page');
  const methods = useForm<FormSchema>({
    mode: 'onSubmit',
    resolver: yupResolver(formSchema),
    defaultValues: {
      bankName: agreementsData?.bank_name ?? '',
      bankMfo: agreementsData?.bank_mfo ?? '',
      bankCheckingAccount: agreementsData?.bank_checking_account ?? '',
      directorName: directorContact?.name ?? '',
      directorPhone: directorContact?.phone_number ?? '',
      addressRow: agreementsData?.address_row ?? '',
    },
  });

  const {
    reset,
    formState: { isDirty, isSubmitting },
  } = methods;

  const updateUserContractorMutation = useUpdateUserContractor();
  const { isSuccess: isUserContractorUpdated } = updateUserContractorMutation;

  useUpdateEffect(() => {
    if (isUserContractorUpdated && agreementsData && directorContact) {
      reset({
        bankName: agreementsData.bank_name,
        bankMfo: agreementsData.bank_mfo,
        bankCheckingAccount: agreementsData.bank_checking_account,
        directorName: directorContact.name,
        directorPhone: directorContact.phone_number,
        addressRow: agreementsData.address_row,
      });
    }
  }, [isUserContractorUpdated, agreementsData, directorContact, reset]);

  const { enqueueSnackbar } = useSnackbar();

  useUpdateEffect(() => {
    if (isUserContractorUpdated) {
      enqueueSnackbar(t('entity-info.entity-info-form.snackbars.entity-info-successfully-changed'), {
        variant: 'success',
      });
    }
  }, [isUserContractorUpdated, enqueueSnackbar]);

  const { setGlobalError } = useGlobalErrorStore();

  const handleSubmit: SubmitHandler<FormSchema> = async formData => {
    try {
      await updateUserContractorMutation.mutateAsync({
        bank_name: formData.bankName,
        bank_mfo: formData.bankMfo,
        bank_checking_account: formData.bankCheckingAccount,
        director_name: formData.directorName,
        director_phone: formData.directorPhone,
        address_row: formData.addressRow,
      });
    } catch (error) {
      if (error) {
        setGlobalError(error);
      }
    }
  };

  const handleReset = () => reset();

  useNavigationPrompt(t('entity-info.entity-info-form.navigation-prompt'), isDirty);

  return (
    <FormProvider isDisabled={isSubmitting} id={formId} methods={methods} onSubmit={handleSubmit}>
      <Grid container spacing={2} mb={5}>
        <Grid xs={12}>
          <Typography component="div" variant="Body/Bold/large">
            {t('entity-info.entity-info-form.form-fields.labels.bank-info')}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="bankName">
              {t('entity-info.entity-info-form.form-fields.labels.bank-name')}
            </CustomFormLabel>
            <RHFTextField fullWidth variant="filled" name="bankName" id="bankName" />
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="bankMfo">
              {t('entity-info.entity-info-form.form-fields.labels.bank-mfo')}
            </CustomFormLabel>
            <RHFNumberTextField fullWidth variant="filled" name="bankMfo" id="bankMfo" />
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="bankCheckingAccount">
              {t('entity-info.entity-info-form.form-fields.labels.bank-checking-account')}
            </CustomFormLabel>
            <RHFNumberTextField fullWidth variant="filled" name="bankCheckingAccount" id="bankCheckingAccount" />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} mb={5}>
        <Grid xs={12}>
          <Typography component="div" variant="Body/Bold/large">
            {t('entity-info.entity-info-form.form-fields.labels.director')}
          </Typography>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="directorName">
              {t('entity-info.entity-info-form.form-fields.labels.director-name')}
            </CustomFormLabel>
            <RHFTextField fullWidth variant="filled" name="directorName" id="directorName" />
          </FormControl>
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="directorPhone">
              {t('entity-info.entity-info-form.form-fields.labels.director-phone')}
            </CustomFormLabel>
            <RHFMaskedPhoneTextField fullWidth variant="filled" name="directorPhone" id="directorPhone" />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid xs={12}>
          <Typography component="div" variant="Body/Bold/large">
            {t('entity-info.entity-info-form.form-fields.labels.requisits')}
          </Typography>
        </Grid>
        <Grid xs={8}>
          <FormControl fullWidth>
            <CustomFormLabel isRequired htmlFor="addressRow">
              {t('entity-info.entity-info-form.form-fields.labels.address-row')}
            </CustomFormLabel>
            <RHFTextField fullWidth variant="filled" name="addressRow" id="addressRow" />
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Typography component="div" variant="Body/small" color="grey.500" mb={1}>
            {t('entity-info.entity-info-form.form-fields.labels.examples.title')}
          </Typography>
          <Typography component="div" variant="Body/medium">
            {t('entity-info.entity-info-form.form-fields.labels.examples.example-1')}
          </Typography>
          <Typography component="div" variant="Body/medium">
            {t('entity-info.entity-info-form.form-fields.labels.examples.example-2')}
          </Typography>
        </Grid>
      </Grid>

      <FormActionbar isFloating isOpen={isDirty} isSubmitting={isSubmitting} formId={formId} onReset={handleReset}>
        {t('entity-info.entity-info-form.form-action-bar.description')}
      </FormActionbar>
    </FormProvider>
  );
};
