import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { SuccessAction } from '@/components/SuccessAction';
import { StyledFormRoot } from '@/pages/auth/components/styles';
import { useAuthMethods } from '@/shared/lib/auth';
import { AuthPaths } from '@/shared/lib/react-router';

import { useClearSignUpData } from './hooks/useClearSignUpData';

export const SignUpFinalPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateSignInTokens } = useAuthMethods();
  const clearSignUpData = useClearSignUpData();
  const { t } = useTranslation('auth-sign-up');

  /* eslint-disable-next-line */
  useEffect(clearSignUpData, []);

  const handleClick = () => {
    if (location.state) {
      const { access_token, refresh_token } = location.state;
      if (access_token && refresh_token && typeof access_token === 'string' && typeof refresh_token === 'string') {
        updateSignInTokens({ access_token, refresh_token });
      }
    }

    navigate(AuthPaths.SIGN_UP.AFTER_SUCCESSFUL_SIGNUP);
  };

  return (
    <StyledFormRoot>
      <SuccessAction
        title={t('sign-up-final-page.texts.title')}
        description={t('sign-up-final-page.texts.description')}
        onActionButtonClick={handleClick}
        actionButtonTitle={t('sign-up-final-page.texts.action-button-text')}
      />
    </StyledFormRoot>
  );
};
