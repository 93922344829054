import { Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import type { MuiFileInputProps } from 'mui-file-input';
import { MuiFileInput } from 'mui-file-input';

import { Label } from '@/components/labels';

// ----------------------------------------------------------------------
type MultipleFileInput = false;
export type RHFFileFieldProps = MuiFileInputProps<MultipleFileInput> & {
  name: string;
  endAdornmentText?: string;
  accept?: string;
};
// ----------------------------------------------------------------------

const StyledFileInput = styled(MuiFileInput)(({ theme, disabled }) => ({
  '.MuiFileInput-TextField': {
    position: 'relative',
  },
  '.MuiInputBase-root': {
    padding: '0 !important',
  },
  label: {
    width: '100%',
  },
  'input + span': {
    padding: theme.spacing(1.5, 15, 1.5, 1.5),
    cursor: disabled ? 'default' : 'pointer',
  },
  '.MuiFileInput-placeholder': {
    fontSize: 12,
  },
  '.hint': {
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: 'translateY(-50%)',
  },
})) as typeof MuiFileInput;

// ----------------------------------------------------------------------

export const RHFFileField = (props: RHFFileFieldProps) => {
  const { t } = useTranslation('form-controls');
  const {
    name,
    helperText,
    endAdornmentText = t('rhf-file-field.end-adornment-text'),
    accept,
    inputProps,
    ...restOfProps
  } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onBlur, onChange, ...restOfFieldProps }, fieldState: { error } }) => {
        const patchedOnChange: RHFFileFieldProps['onChange'] = file => {
          onChange(file);
          onBlur();
        };

        return (
          <Fragment>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */}
            {/* @ts-expect-error */}
            <StyledFileInput
              {...restOfFieldProps}
              inputRef={ref}
              fullWidth
              error={!!error}
              helperText={error ? error?.message : helperText}
              InputProps={{
                startAdornment: null,
                endAdornment: (
                  <Label className="hint" data-testid="select-file-label">
                    {endAdornmentText}
                  </Label>
                ),
              }}
              inputProps={{
                accept,
                'data-testid': `file-input-${name}`,
                ...inputProps,
              }}
              onChange={patchedOnChange}
              {...restOfProps}
            />
          </Fragment>
        );
      }}
    />
  );
};
