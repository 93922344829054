import { useTranslation } from 'react-i18next';

import { BadgeBase } from '@/components/badges';
import { ReactComponent as CashbackSVG } from '@/shared/assets/icons/common/cashback.svg';

export const DorimPromoBadge = () => {
  const { t } = useTranslation();
  return (
    <BadgeBase badgeColor="green" labelIcon={<CashbackSVG />}>
      {t('Dorim.Promo')}
    </BadgeBase>
  );
};
