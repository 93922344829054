import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItemButton, Popover } from '@mui/material';

import { ReactComponent as CallIcon } from '@/shared/assets/icons-2.0/call.svg';
import { ReactComponent as TelegramIcon } from '@/shared/assets/icons-2.0/telegram.svg';
import { SUPPORT_PHONE_CALLTO, SUPPORT_PHONE_FORMATTED, TELEGRAM_CHAT_LINK } from '@/shared/constants/dorim.constants';

import { ANCHOR_ORIGIN_CONFIG, TRANSFORM_ORIGIN_CONFIG } from './constants';
import {
  StyledButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledPopoverContent,
  StyledPopoverTitle,
  StyledQuestionIcon,
} from './styles';

export const SupportButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation('auth-page');
  const onClickButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeHandler = () => setAnchorEl(null);
  const isOpen = Boolean(anchorEl);
  return (
    <Fragment>
      <StyledButton onClick={onClickButtonHandler} sx={{ boxShadow: 3 }}>
        <StyledQuestionIcon />
      </StyledButton>
      <Popover
        elevation={3}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closeHandler}
        anchorOrigin={ANCHOR_ORIGIN_CONFIG}
        transformOrigin={TRANSFORM_ORIGIN_CONFIG}
      >
        <StyledPopoverContent>
          <List
            component="nav"
            aria-labelledby="support-subheader"
            subheader={
              <StyledPopoverTitle id="support-subheader">
                {t('components.support-link.support', { ns: 'auth-page' })}
              </StyledPopoverTitle>
            }
          >
            <ListItemButton component="a" href={TELEGRAM_CHAT_LINK} target="_blank">
              <StyledListItemIcon>
                <TelegramIcon />
              </StyledListItemIcon>
              <StyledListItemText primary="Telegram" />
            </ListItemButton>
            <ListItemButton component="a" href={`tel:${SUPPORT_PHONE_CALLTO}`}>
              <StyledListItemIcon>
                <CallIcon />
              </StyledListItemIcon>
              <StyledListItemText primary={SUPPORT_PHONE_FORMATTED} />
            </ListItemButton>
          </List>
        </StyledPopoverContent>
      </Popover>
    </Fragment>
  );
};
