import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
  type ConfirmationDialogContainerProps,
} from '@/components/dialogs';

import { useSpecificationIdFromPathParams } from '../../../../../../../../../../lib';
import { useSpecificationContextWindowsVisibility, useSpecificationStoreActions } from '../../../../../../../../../lib';
import { useAcceptSpecificationContract } from './lib';

type SpecificationContractAcceptanceDialogProps = Omit<ConfirmationDialogContainerProps, 'subject' | 'open'>;

export const SpecificationContractAcceptanceDialog = (props: SpecificationContractAcceptanceDialogProps) => {
  const specificationId = useSpecificationIdFromPathParams();

  const { isAcceptContractConfirmationDialogVisible } = useSpecificationContextWindowsVisibility();
  const { closeAcceptContractConfirmationDialog } = useSpecificationStoreActions();

  const { isLoading, mutate: acceptSpecification } = useAcceptSpecificationContract();
  const { t } = useTranslation('specifications-detail-page');

  const acceptSpecificationContractDocument = () => {
    acceptSpecification(
      { specificationId },
      {
        onSuccess: closeAcceptContractConfirmationDialog,
      },
    );
  };

  return (
    <ConfirmationDialogContainer
      open={isAcceptContractConfirmationDialogVisible}
      subject="confirm"
      onClose={closeAcceptContractConfirmationDialog}
      {...props}
    >
      <ConfirmationDialogHeader>{t('dialogs.specification-contract-acceptance-dialog.title')}</ConfirmationDialogHeader>
      <Typography variant="Body/medium" color="neutral.700">
        {t('dialogs.specification-contract-acceptance-dialog.description')}
      </Typography>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={closeAcceptContractConfirmationDialog} />
        <ConfirmationDialogActionProceedButton
          autoFocus
          loading={isLoading}
          onClick={acceptSpecificationContractDocument}
        >
          {t('dialogs.specification-contract-acceptance-dialog.actions.accept')}
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
