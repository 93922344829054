import { useQuery } from '@tanstack/react-query';

import {
  DorimPromoApi,
  type FetchSalesPlanMarketingOrgParams,
  type SpecificationSalesPlanMarketingOrg,
} from '@/api/domains/dorim-promo.api';
import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';

type UseSalesPlanMarketingOrgParams<TData> = FetchSalesPlanMarketingOrgParams & {
  select?: (marketingOrg: SpecificationSalesPlanMarketingOrg | undefined) => TData | undefined;
};

export const useSalesPlanMarketingOrg = <TData = SpecificationSalesPlanMarketingOrg | undefined>({
  agreement_id,
  marketing_org_id,
  select,
}: UseSalesPlanMarketingOrgParams<TData>) =>
  useQuery({
    queryKey: DorimPromoQueryKeys.getSalesPlanMarketingOrgKey({ agreement_id, marketing_org_id }),
    queryFn: () => DorimPromoApi.fetchSalesPlanMarketingOrg({ agreement_id, marketing_org_id }),
    select,
    staleTime: 1000 * 60 * 5,
  });
